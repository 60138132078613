import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { hideContent } from 'src/components/8thWallSkinners/utils';

import useMount from 'src/hooks/useMount';

import 'src/styles/8thwall.scss';

function CameraPermissionsErrorScreenSkinner({
  AppleComponent,
  AndroidComponent,
}) {
  const appleContainerRef = useRef();
  const androidContainerRef = useRef();
  const [appleContainerExists, setAppleContainerExists] = useState(false);
  const [androidContainerExists, setAndroidContainerExists] = useState(false);

  useMount(() => {
    const appleContainer = document.querySelector(
      '#cameraPermissionsErrorApple',
    );
    const androidContainer = document.querySelector(
      '#cameraPermissionsErrorAndroid',
    );

    if (appleContainer && !appleContainerRef.current) {
      appleContainerRef.current = appleContainer;

      hideContent(appleContainer);

      setAppleContainerExists(true);
      // observer.disconnect();
    }

    if (androidContainer && !androidContainerRef.current) {
      androidContainerRef.current = androidContainer;

      hideContent(androidContainer);

      setAndroidContainerExists(true);
      // observer.disconnect();
    }

    const observer = new MutationObserver(() => {
      // const appleContainer = document.querySelector(
      //   '#cameraPermissionsErrorApple',
      // );
      // const androidContainer = document.querySelector(
      //   '#cameraPermissionsErrorAndroid',
      // );
      if (appleContainer && !appleContainerRef.current) {
        appleContainerRef.current = appleContainer;
        hideContent(appleContainer);
        setAppleContainerExists(true);
        observer.disconnect();
      }
      if (androidContainer && !androidContainerRef.current) {
        androidContainerRef.current = androidContainer;
        hideContent(androidContainer);
        setAndroidContainerExists(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      {appleContainerExists && appleContainerRef.current
        ? createPortal(
            <AppleComponent type="camera" />,
            appleContainerRef.current,
          )
        : null}
      {androidContainerExists && androidContainerRef.current
        ? createPortal(
            <AndroidComponent type="camera" />,
            androidContainerRef.current,
          )
        : null}
    </>
  );
}

CameraPermissionsErrorScreenSkinner.propTypes = {
  AppleComponent: PropTypes.elementType.isRequired,
  AndroidComponent: PropTypes.elementType.isRequired,
};

export default CameraPermissionsErrorScreenSkinner;
