/* eslint-disable no-console */
/* eslint-disable no-undef */
const { THREE } = window;

const particleComponent = {
  schema: {
    isExploding: { type: 'boolean', default: false },
    amount: { type: 'number', default: 100 },
    rate: { type: 'number', default: 3 },
    radius: { type: 'number', default: 15 },
    areaWidth: { type: 'number', default: 3 },
    areaHeight: { type: 'number', default: 1 },
    fallingHeight: { type: 'number', default: 9 },
    fallingSpeed: { type: 'number', default: 8 },
    colors: { type: 'array', default: ['silver'] }, // Default should be an array
    enableShadows: { type: 'boolean', default: false },
  },

  init() {
    this.booms = [];
    this.geometry = new THREE.BufferGeometry();
    this.texture = new THREE.TextureLoader().load('/images/sparkle.png');
  },

  explode() {
    const boom = new THREE.Object3D();
    boom.life = Math.random() * 8 + 8;
    boom.position.set(
      -(this.data.areaWidth / 2) + this.data.areaWidth * Math.random(),
      this.data.fallingHeight + this.data.areaHeight - this.data.fallingSpeed,
      -(this.data.areaWidth / 2) + this.data.areaWidth * Math.random(),
    );
    this.el.object3D.add(boom);
    this.booms.push(boom);

    for (let i = 0; i < this.data.amount; i++) {
      const color =
        this.data.colors[Math.floor(Math.random() * this.data.colors.length)];

      const material = new THREE.MeshStandardMaterial({
        color,
        emissive: new THREE.Color('silver'),
        emissiveIntensity: 0,
        side: THREE.DoubleSide,
      });
      const particle = new THREE.Mesh(this.geometry, material);
      particle.castShadow = this.data.enableShadows;
      boom.add(particle);

      particle.life = 1;
      particle.destination = {
        x: (Math.random() - 0.5) * (this.data.radius * 2) * Math.random(),
        y: (Math.random() - 0.5) * (this.data.radius * 2) * Math.random(),
        z: (Math.random() - 0.5) * (this.data.radius * 2) * Math.random(),
      };

      particle.rotation.set(
        Math.random() * Math.PI * 2,
        Math.random() * Math.PI * 2,
        Math.random() * Math.PI * 2,
      );

      const size = Math.random() * 2 + 1;
      particle.scale.set(size, size, size);
      particle.rotateSpeed = new THREE.Vector3(
        Math.random() * 0.8 - 0.4,
        Math.random() * 0.8 - 0.4,
        Math.random() * 0.8 - 0.4,
      );

      // Create and add glow sprite
      const glowSprite = new THREE.Sprite(
        new THREE.SpriteMaterial({
          alphaMap: this.texture,
          map: this.texture,
          color: 0xffffff,
          blending: THREE.AdditiveBlending,
          transparent: true,
          opacity: 0.1,
          depthWrite: false,
          depthTest: false,
        }),
      );
      glowSprite.scale.set(0.2, 0.2, 0.2); // Adjust size as necessary
      particle.add(glowSprite);
    }

    boom.dispose = () => {
      for (let i = 0; i < boom.children.length; i++) {
        const particle = boom.children[i];
        if (particle.material) {
          particle.material.dispose();
        }
        if (particle.geometry) {
          particle.geometry.dispose();
        }
        boom.remove(particle);
      }
      this.el.object3D.remove(boom);
    };
  },

  tick() {
    if (this.data.isExploding && Math.random() < this.data.rate / 100) {
      this.explode();
    }

    for (let i = this.booms.length - 1; i >= 0; i--) {
      const boom = this.booms[i];

      for (let k = boom.children.length - 1; k >= 0; k--) {
        const particle = boom.children[k];

        particle.destination.y -= THREE.MathUtils.randFloat(0.1, 0.3);
        particle.life -= THREE.MathUtils.randFloat(0.005, 0.01);

        particle.position.x +=
          (particle.destination.x - particle.position.x) / 200;
        particle.position.y +=
          (particle.destination.y - particle.position.y) / 200;
        particle.position.z +=
          (particle.destination.z - particle.position.z) / 200;

        particle.rotation.y += particle.rotateSpeed.y;
        particle.rotation.x += particle.rotateSpeed.x;
        particle.rotation.z += particle.rotateSpeed.z;

        // Dynamic glow effect
        const glowSprite = particle.children[0]; // Assuming sprite is first child
        if (glowSprite) {
          glowSprite.material.opacity = THREE.MathUtils.lerp(
            glowSprite.material.opacity,
            THREE.MathUtils.randFloat(0.2, 0.5),
            0.1,
          );
        }

        particle.material.opacity -= THREE.MathUtils.randFloat(0.005, 0.01);

        if (particle.position.y < -this.data.fallingHeight) {
          particle.material.dispose();
          particle.geometry.dispose();
          boom.remove(particle);
        }
      }

      if (boom.children.length <= 0) {
        boom.dispose();
        this.booms.splice(i, 1);
      }
    }
  },
};

export default particleComponent;
