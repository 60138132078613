import { get, post } from 'aws-amplify/api';
import store from 'src//store';
export const getProduct = async ({
  language,
  latitude,
  longitude,
  productId,
  couponCode,
  couponCodesActive,
  excludedProductIds,
}) => {
  console.log('API CAll', couponCodesActive);
  const params = {};

  if (language) {
    params.language = language;
  }

  if (latitude) {
    params.latitude = latitude;
  }

  if (longitude) {
    params.longitude = longitude;
  }

  if (productId) {
    params.productId = productId;
  }

  if (couponCode) {
    params.couponCode = couponCode;
  }

  if (couponCodesActive === 'false') {
    params.couponCodesActive = false;
  }

  if (excludedProductIds && excludedProductIds.length > 0) {
    params.excludedProductIds = excludedProductIds;
  }

  return get({
    apiName: 'wtgApi',
    path: `get-product`,
    options: {
      queryParams: params,
    },
  });
};

export const detectGift = async ({ image, useVision }) =>
  post({
    apiName: 'wtgApi',
    path: 'detect-gift',
    options: {
      body: {
        image,
        useVision,
      },
    },
  });

export const createRedemption = async ({
  userId,
  productId,
  code,
  codeValue,
}) => {
  const response = await store.app.client.value.models.Redemption.create({
    userId,
    productId,
    code,
    codeValue,
  });
  return response;
};

const api = {
  getProduct,
  detectGift,
  createRedemption,
};

export default api;
