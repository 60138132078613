/* eslint-disable */
import { signal, computed } from '@preact/signals-react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import ButtonTapGift from '../../components/ButtonTapGift';
import HudWrapper from '../../components/HudWrapper';
import LinkOutModal from '../../components/LinkOutModal';
import ProductCard from '../../components/ProductCard';
import TermsDrawer from '../../components/TermsDrawer';

import HeaderNav from 'src/components/HeaderNav';

import useMount from 'src/hooks/useMount';

import { dataLayer } from 'src/utils/gtm';
import useAPI from 'src/hooks/useApi';

import { giftPlaced, enteredCameraView } from 'src/constants/analyticsEvents';

import {
  PRESENT_FOUND,
} from 'src/aframe/components/tapToPlace';
import giftFoundAnim from 'src/assets/images/Gift-Icon_Found_PlayOnce.gif';
import giftNotFoundAnim from 'src/assets/images/Gift-Icon_Search_no_crosshairs.gif';
import tapPlaceIcon from 'src/assets/images/Tap_GIF.gif';
import tapIcon from 'src/assets/images/tap-show.png';
import crosshair from 'src/assets/images/crosshair.png';
import store from 'src/store';
import useTranslation from 'src/hooks/useTranslation';
import useNavigateTo from '../../hooks/useNavigateTo';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';
import style, {
  experienceUI,
  reticle,
  instructionContainer,
  instructionContainerText,
  reticleAnim,
  tapAnim,
  tapGiftButton,
  notFound,
  boxOverlay,
  instructionContainerWithReticle,
  imageWrapper,
  instructionContainerTextEn,
} from './ExperienceUI.module.scss';
import { GIFT_BOX__TAPPED } from '../../aframe/components/tapToPlace';

function ExperienceUI() {
  const { getProduct, createRedemption } = useAPI();
  const userId = store.app.userId.value;
  const currentProduct = store.app.currentProduct.value;
  const translations = useTranslation();
  const [showTapToPlaceHelp, setTapToPlaceHelp] = useState(false);
  const navigateTo = useNavigateTo();

  useMount(() => {
    if (XR8) {
      XR8.resume();
    }

    const bgMusicElem = document.getElementById('bgmusic');
    if (bgMusicElem) {
      bgMusicElem.components.sound.playSound();
    }

    window.addEventListener(GIFT_BOX__TAPPED, handleGiftBoxTapped);

    window.addEventListener(PRESENT_FOUND, handleBoxPlaced);
    
    window.addEventListener('HANDLE_REDEMPTION_ASYNC', handleRedemptionAsync);

    dispatchAnalyticsEvent({
      event: 'pageView',
      page: {
        language: store.app.userLanguage.value,
        authenticated: 'logged out',
        name: 'bmo:uwtg:gift finder',
        channel: 'bmo',
        site: 'bmo:uwtg',
        section1: 'bmo:uwtg:gift finder',
        section2: '',
        section3: '',
      },
      user: {
        darid: '',
        sessionid: '',
        customerType: '',
        productLoginType: '',
      },
      products: [],
      environment: {
        platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
          window.navigator.userAgent
        } )`,
        version: '',
      },
      conversion: {
        type: '',
        name: '',
        id: '',
        stage: '',
        applicationid: '',
      },
      notification: [
        {
          code: '',
          type: '', // possible values "error" or "warning"
          display: '', // possible values based on "inline","page","banner"
        },
      ],
      custom: {
        variables: {},
        events: '',
      },
    });
    
  });

  useEffect(() => {
    console.log('SCAN STATE', store.app.scanState.value);
    if (
      store.app.scanState.value === 'found' ||
      store.app.scanState.value === 'giftBoxRendered'
    ) {
      // PLAY FOUND SOUND
      const soundEntity = document.getElementById('found');
      soundEntity.components.sound.playSound();

      // Handle for a gift being found
      setTimeout(() => {
        setTapToPlaceHelp(true);
        // handleTapForAGiftFromUs();
        handleFound();
      }, 2500);
    } else {
      setTapToPlaceHelp(false);
    }

    if (store.app.scanState.value === 'giftBoxTapped') {
      setTapToPlaceHelp(false);
    }
  }, [store.app.scanState.value]);

  const instructionText = {
    scanning: translations.sections.scanningView.copy.instructionsScanning,
    notFound: translations.sections.scanningView.copy.instructionsNotFound,
    found: translations.sections.scanningView.copy.instructionsFound,
    tapToPlace: translations.sections.scanningView.copy.instructionsTapToPlace,
    giftBoxRendered: translations.sections.scanningView.copy.instructionsTap,
    giftBoxTapped: '',
  };

  const currentInstructionText = instructionText[store.app.scanState.value]
  
  const mockProduct = {
    product_id: 'WTG0146',
    product_name: 'Adult Bomber Jacket Buffalo Check',
    product_url:
      'https://myolblues.com/collections/buffalo-check-plaid-wear/products/adult-fleece-bomber-jacket-buffalo-check-tan',
    product_category: 'Clothing & Accessories',
    product_image:
      'http://bmowtg-staging.fcbwork.com/product-images/MyOlBlues-AdultBomberJacketBuffaloCheckTan.jpg',
    business_name: "My Ol' Blues",
    segment: 'Indigenous and Women-owned',
    optional_field_1: 'Canada',
    optional_field_2: 'myolblues.com/collections/all-products',
    optional_field_3: '',
    optional_field_4: '',
    couponCode: 'BMO15',
    couponValue: 15,
  };
  const product = store.app.currentProduct.value || mockProduct;

  const closeModal = () => {
    store.app.modalShow.value = false;

    if (store.app.modalType.value === 'new-tab') {
      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'cancel link warning--page',
            region: 'page',
          },
        ],
      });
  
    }

    if (store.app.modalType.value === 'discover-more') {
      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'cancel--page',
            region: 'page',
          },
        ],
      });
    }

    if (store.app.modalType.value === 'new-scan') {
      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'scan another gift--page',
            region: 'page',
          },
        ],
      });
    }
  };

  const handleRedemptionAsync = async () => {
    const redemptionBody = {
      userId: store.app.userId.value,
      productId: store.app.currentProduct.value.product_id,
      code: store.app.currentProduct.value.couponCode,
      codeValue: store.app.currentProduct.value.couponValue,
    }
    // console.log('CREATING REDEMPTION IN ASYNC HANDLER', redemptionBody)
    if(!store.app.hasCreatedRedemption.value) {
      await createRedemption({
        userId: store.app.userId.value,
        productId: store.app.currentProduct.value.product_id,
        code: store.app.currentProduct.value.couponCode,
        codeValue: store.app.currentProduct.value.couponValue,
      });
      store.app.hasCreatedRedemption.value = true;
    } 
  }

  const handleModalConfirm = async () => {

    // HANDLING FOR REDEEM
    if (store.app.modalType.value === 'new-tab') {
      store.app.modalShow.value = false;

      const redeemed = store.app.userProductsRedeemed.value;
      redeemed.push(product.product_id);
      store.app.userProductsRedeemed.value = redeemed;

     // REDEMPTION USED TO BE HERE

      const linkOutUrl = product.optional_field_2.startsWith('https://') 
      ? product.optional_field_2 
      : `https://${product.optional_field_2}`;
      console.log('Link out url', linkOutUrl);
      window.open(linkOutUrl);
      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'continue link warning--page',
            region: 'page',
          },
        ],
      });
    }

    if (store.app.modalType.value === 'discover-more') {
      store.app.modalShow.value = false;

      // Leave experience
      // navigateTo('/');

      const linkOut =
        store.app.userLanguage.value === 'en'
          ? 'https://bmowrapthegood.com'
          : 'https://bmoemballezpourlebien.com';
      window.open(linkOut);
    }

    if (store.app.modalType.value === 'new-scan') {
      store.app.modalShow.value = false;

      // fetch new product
      await getProduct({
        language: store.app.userLanguage.value,
        productId: store.app.productIdOverride.value,
        couponCode: store.app.couponCodeOverride.value,
        couponCodesActive: store.app.couponCodesActive.value,
      });

      // RESET REDEMPTION TRACKING
      store.app.hasCreatedRedemption.value = false;

      // Reset 3d UI
      window.dispatchEvent(new Event('RESTART_SCANNING'));

      // Reset app state
      // CHECK IF ALREADY AT EXPERIENCE PAGE
      // if (!scanningActive) {
      //   navigateTo(navToLink);
      // }
      
      // Set scan state
      store.app.scanState.value = 'scanning';

      // TODO - RESET 15s TIMER
      window.dispatchEvent(new Event('RESTART_SCAN_TIMER'));

      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'global',
            details: 'continue--global',
            region: 'page',
          },
        ],
      });
    }

    if (store.app.modalType.value === 'switch-language-en-to-fr') {
      store.app.modalShow.value = false;
      
      // Check if we're on the AR domain
      if (window.location.origin === 'https://ar.bmowrapthegood.com') {
        window.location.href = 'https://ar.bmoemballezpourlebien.com';
      } else {
        // Existing logic for non-AR domains
        navigateTo(`/?lang=${store.app.userLanguage.value}`);
        window.open(`${window.location.origin}?lang=fr`, '_blank');
      }
    }
  
    if (store.app.modalType.value === 'switch-language-fr-to-en') {
      store.app.modalShow.value = false;
      
      // Check if we're on the AR domain
      if (window.location.origin === 'https://ar.bmoemballezpourlebien.com') {
        window.location.href = 'https://ar.bmowrapthegood.com';
      } else {
        // Existing logic for non-AR domains
        navigateTo(`/?lang=${store.app.userLanguage.value}`);
        window.open(`${window.location.origin}?lang=en`, '_blank');
      }
    }
  };

  const handleTapForAGiftFromUs = () => {
    window.dispatchEvent(new Event('TAP_FOR_A_GIFT_FROM_US'));
    store.app.showInstructionalOverlay.value = true;
    store.app.boxPlaceEnabled.value = true;
    // store.app.scanState.value = 'tapToPlace';

    // Analytics call
    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'global',
          details: 'gift from us--page',
          region: 'page',
        },
      ],
    });

  };

  const handleFound = () => {
    window.dispatchEvent(new Event('TAP_FOR_A_GIFT_FROM_US'));
    store.app.showInstructionalOverlay.value = true;
    store.app.boxPlaceEnabled.value = true;
  };

  const handleBoxPlaced = () => {
    if (store.app.scanState.value === 'notFound') {
      setTapToPlaceHelp(true);
    }
    store.app.scanState.value = 'giftBoxRendered';
    setTapToPlaceHelp(false);

    dispatchAnalyticsEvent({
      event: 'pageView',
      page: {
        language: store.app.userLanguage.value,
        authenticated: 'logged out',
        name: 'bmo:uwtg:tap to unwrap',
        channel: 'bmo',
        site: 'bmo:uwtg',
        section1: 'bmo:uwtg:tap to unwrap',
        section2: '',
        section3: '',
      },
      user: {
        darid: '',
        sessionid: '',
        customerType: '',
        productLoginType: '',
      },
      products: [],
      environment: {
        platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
          window.navigator.userAgent
        } )`,
        version: '',
      },
      conversion: {
        type: '',
        name: '',
        id: '',
        stage: '',
        applicationid: '',
      },
      notification: [
        {
          code: '',
          type: '', // possible values "error" or "warning"
          display: '', // possible values based on "inline","page","banner"
        },
      ],
      custom: {
        variables: {},
        events: '',
      },
    });
  };

  const handleGiftBoxTapped = () => {
    setTimeout(() => {
      store.app.boxPlaceEnabled.value = false;
      store.app.showProductCard.value = true;
      dispatchAnalyticsEvent({
        event: 'pageView',
        page: {
          language: store.app.userLanguage.value,
          authenticated: 'logged out',
          name: 'bmo:uwtg:product reveal',
          channel: 'bmo',
          site: 'bmo:uwtg',
          section1: 'bmo:uwtg:product reveal',
          section2: '',
          section3: '',
        },
        user: {
          darid: '',
          sessionid: '',
          customerType: '',
          productLoginType: '',
        },
        products: [],
        environment: {
          platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
            window.navigator.userAgent
          } )`,
          version: '',
        },
        conversion: {
          type: '',
          name: '',
          id: '',
          stage: '',
          applicationid: '',
        },
        notification: [
          {
            code: '',
            type: '', // possible values "error" or "warning"
            display: '', // possible values based on "inline","page","banner"
          },
        ],
        custom: {
          variables: {},
          events: '',
        },
      });
    }, 4000);

    store.app.scanState.value = 'giftBoxTapped';

     // LOG REDEMPTION VIA API
     if (store.app.currentProduct.value && store.app.currentProduct.value.couponCode) {
      window.dispatchEvent(new Event('HANDLE_REDEMPTION_ASYNC'));
     } else {
      console.log('No coupon code available');
     }

    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'global',
          details: 'tap to unwrap--page',
          region: 'page',
        },
      ],
    });
  };

  return (
    <div
      className={classNames(experienceUI, {
        [boxOverlay]: store.app.boxPlaceEnabled.value,
      })}
    >
      <HudWrapper>
        <HeaderNav scanningActive />
        {store.app.scanState.value === 'scanning' && (
          <div className={reticle}>
            <div className={imageWrapper}>
              <img
                className={reticleAnim}
                src={giftNotFoundAnim}
                alt="Viewfinder Animation"
              />
              <img className={style.crosshair} src={crosshair} />
            </div>
            <div className={instructionContainerWithReticle}>
              <p className={classNames(instructionContainerText, store.app.userLanguage.value === 'en' && instructionContainerTextEn)}>
                {currentInstructionText}
              </p>
            </div>
          </div>
        )}

        {store.app.scanState.value === 'notFound' && (
          <div className={reticle}>
            <div className={imageWrapper}>
              <img
                className={reticleAnim}
                src={showTapToPlaceHelp ? tapPlaceIcon : giftNotFoundAnim}
                alt="Viewfinder Animation"
              />
            </div>
            <div
              className={classNames(instructionContainer, {
                [notFound]: store.app.scanState.value === 'notFound',
              })}
            >
              <p className={classNames(instructionContainerText, store.app.userLanguage.value === 'en' && instructionContainerTextEn)}>
                {currentInstructionText}
              </p>
              {store.app.scanState.value === 'notFound' && (
                <ButtonTapGift
                  leftIcon={tapIcon}
                  className={tapGiftButton}
                  onClick={handleTapForAGiftFromUs}
                >
                  {store.app.couponCodesActive.value !== 'false'
                    ? translations.sections.scanningView.copy
                        .instructionsNotFoundCtaCopy
                    : translations.sections.scanningView.copy
                        .instructionsNotFoundCtaNoCodesCopy}
                </ButtonTapGift>
              )}
            </div>
          </div>
        )}
         {/* DEPRECATED */}
        {store.app.scanState.value === 'tapToPlace' && (
          <div className={reticle}>
              <div className={imageWrapper}>
                <img
                  className={tapAnim}
                  src={tapPlaceIcon}
                  alt="Tap to place help"
                />
              </div>
              <div
                className={classNames(
                  instructionContainerWithReticle,
                )}
              >
                {/* Adding this ternary as a workaround. I have no time for figuring that logic */}
                <p className={classNames(instructionContainerText, store.app.userLanguage.value === 'en' && instructionContainerTextEn)}>
                  {currentInstructionText}
                </p>
              </div>
            </div>
        )}
        {store.app.scanState.value === 'found' ||
          (store.app.scanState.value === 'giftBoxRendered' && (
            <div className={reticle}>
              <div className={imageWrapper}>
                <img
                  className={showTapToPlaceHelp ? tapAnim : reticleAnim}
                  src={showTapToPlaceHelp ? tapPlaceIcon : giftFoundAnim}
                  alt="Gift Found Animation"
                />
              </div>
              <div
                className={classNames(
                  instructionContainerWithReticle,
                )}
              >
                {/* Adding this ternary as a workaround. I have no time for figuring that logic */}
                <p className={classNames(instructionContainerText, store.app.userLanguage.value === 'en' && instructionContainerTextEn)}>
                  {showTapToPlaceHelp
                    ? currentInstructionText
                    : instructionText['found']}
                </p>
              </div>
            </div>
          ))}
        {store.app.scanState.value === 'giftBoxTapped' &&
          store.app.showProductCard.value && <ProductCard product={product} />}
        {store.app.couponCodesActive.value !== 'false' && store.app.scanState.value === 'giftBoxTapped' && store.app.showProductCard.value && <TermsDrawer />}
        {store.app.modalShow.value && store.app.modalType.value && (
          <LinkOutModal
            type={store.app.modalType.value}
            onClose={closeModal}
            onContinue={handleModalConfirm}
            product={product}
          />
        )}
      </HudWrapper>
    </div>
  );
}

export default ExperienceUI;
