// eslint-disable-next-line import/no-unresolved, import/extensions, import/order
// import awsExports from './aws-exports';
// import { Amplify } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.scss';
import 'src/styles/fonts.scss';
import 'src/styles/global.scss';
// import 'normalize.css';

// import { Wrapper } from '@googlemaps/react-wrapper';
// import outputs from '../amplify_outputs.json';
import ExperienceSceneScreen from 'src/views/ExperienceSceneScreen';
import ExperienceUI from 'src/views/ExperienceUI';
// import GeolocationPermissionsErrorScreen from 'src/views/GeolocationPermissionsErrorScreen';
// import HomeScreen from 'src/views/HomeScreen';

import useAPI from 'src/hooks/useApi';
import useMount from 'src/hooks/useMount';
import usePreloadAssets from 'src/hooks/usePreloadAssets';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import store from 'src/store';

import AccessControl from './components/AccessControl';
import DesktopCurtain from './components/DesktopCurtain';
// import HeaderNav from './components/HeaderNav';
// eslint-disable-next-line import/no-unresolved
import RotateCurtain from './components/RotateCurtain';
import UserPositionContextProvider from './contextProviders/UserPositionContextProvider';
// import AndroidCameraPermissionsErrorScreen from './views/AndroidCameraPermissionsErrorScreen';
import LandingPage from './views/LandingPage';
import LoadingScreen from './views/LoadingScreen';
import PermissionsRequiredScreen from './views/PermissionsRequiredScreen';

// const API_KEY = '1234566'; // import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
// Amplify.configure(outputs);
// const config = Amplify.getConfig();
// Amplify.configure({
//   ...config,
//   API: {
//     REST: {
//       [outputs.custom.API.wtgApi.apiName]: {
//         endpoint: outputs.custom.API.wtgApi.endpoint,
//         region: outputs.custom.API.wtgApi.region,
//         apiName: outputs.custom.API.wtgApi.apiName,
//       },
//     },
//   },
// });

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <DesktopCurtain whitelist={['/rules']} />
        <RotateCurtain whitelist={['/rules']} />
        <LandingPage />,
      </>
    ),
  },
  {
    path: '/loading',
    element: (
      <>
        <DesktopCurtain whitelist={['/rules']} />
        <RotateCurtain whitelist={['/rules']} />
        <LoadingScreen />,
      </>
    ),
  },
  {
    path: '/experience',
    element: (
      <>
        <DesktopCurtain whitelist={['/rules']} />
        <RotateCurtain whitelist={['/rules']} />
        <AccessControl>
          <UserPositionContextProvider>
            <ExperienceSceneScreen />
          </UserPositionContextProvider>
        </AccessControl>
      </>
    ),
    children: [
      {
        path: 'get-permissions',
        element: <LoadingScreen />,
      },
      {
        path: 'geolocation-permissions',
        element: <PermissionsRequiredScreen />,
      },
      {
        path: 'scan-gift',
        element: <ExperienceUI />,
      },
    ],
  },
]);

function App({ client }) {
  usePreloadAssets();
  store.app.client.value = client;

  const { detectGift, getUserId, setUserId } = useAPI();
  const scanStopEvent = new CustomEvent('stopScanning', {
    detail: { scanStatus: 'stop' },
  });

  useEffect(() => {
    if (store.app.xrReady.value) {
      const timeoutId = setTimeout(() => {
        // Code to execute after 15 seconds
        console.log('20 seconds have passed!');
        if (store.app.scanState.value !== 'found') {
          store.app.scanState.value = 'notFound';
        }

        window.dispatchEvent(scanStopEvent);
        dispatchAnalyticsEvent({
          event: 'pageView',
          page: {
            language: store.app.userLanguage.value,
            authenticated: 'logged out',
            name: 'bmo:uwtg:no gift found',
            channel: 'bmo',
            site: 'bmo:uwtg',
            section1: 'bmo:uwtg:no gift found',
            section2: '',
            section3: '',
          },
          user: {
            darid: '',
            sessionid: '',
            customerType: '',
            productLoginType: '',
          },
          products: [],
          environment: {
            platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
              window.navigator.userAgent
            } )`,
            version: '',
          },
          conversion: {
            type: '',
            name: '',
            id: '',
            stage: '',
            applicationid: '',
          },
          notification: [
            {
              code: '',
              type: '', // possible values "error" or "warning"
              display: '', // possible values based on "inline","page","banner"
            },
          ],
          custom: {
            variables: {},
            events: '',
          },
        });
      }, 20000);
      store.app.productNotFoundTimeoutId.value = timeoutId;
      return () => clearTimeout(timeoutId);
    }

    return () => {};
  }, [store.app.xrReady.value]); // eslint-disable-line

  const compressImage = async (imageData, targetWidth = 512) =>
    new Promise((resolve, reject) => {
      const img = new Image();

      // Handle both Blob/File and Base64 inputs
      if (imageData instanceof Blob) {
        img.src = URL.createObjectURL(imageData);
      } else if (imageData.startsWith('data:')) {
        img.src = imageData;
      } else {
        img.src = `data:image/jpeg;base64,${imageData}`;
      }

      img.onload = () => {
        // Clean up object URL if we created one
        if (imageData instanceof Blob) {
          URL.revokeObjectURL(img.src);
        }

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const width = targetWidth;
        const height = (img.height / img.width) * width;
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        // Return base64 string without data URL prefix if original didn't have it
        const compressedImageData = canvas.toDataURL('image/jpeg', 0.8);
        resolve(
          imageData.startsWith('data:')
            ? compressedImageData
            : compressedImageData.split(',')[1],
        );
      };

      img.onerror = reject;
    });

  const customLabelGiftFound = (labels) => {
    if (labels && labels.length > 0) {
      return labels.some(
        (label) => label.Name === 'Gift' || label.Name === 'gift',
      );
    }
    return false;
  };

  const parseVisionResponse = (response) => {
    // console.log('Vision Response', response);
    try {
      if (!response?.wrappedGift) {
        return false;
      }
      // console.log(
      //   'Vision data after parsin - Wrapped Giftg',
      //   response.wrappedGift,
      // );
      return response?.wrappedGift === true;
    } catch (error) {
      console.error('Error parsing vision response:', error);
      return false;
    }
  };

  const setProductToFound = () => {
    if (
      store.app.scanState.value !== 'giftBoxRendered' &&
      store.app.scanState.value !== 'found'
    ) {
      store.app.scanState.value = 'found';
      window.dispatchEvent(scanStopEvent);
      window.dispatchEvent(new Event('PRODUCT_FOUND'));

      dispatchAnalyticsEvent({
        event: 'pageView',
        page: {
          language: store.app.userLanguage.value,
          authenticated: 'logged out',
          name: 'bmo:uwtg:gift found',
          channel: 'bmo',
          site: 'bmo:uwtg',
          section1: 'bmo:uwtg:gift found',
          section2: '',
          section3: '',
        },
        user: {
          darid: '',
          sessionid: '',
          customerType: '',
          productLoginType: '',
        },
        products: [],
        environment: {
          platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
            window.navigator.userAgent
          } )`,
          version: '',
        },
        conversion: {
          type: '',
          name: '',
          id: '',
          stage: '',
          applicationid: '',
        },
        notification: [
          {
            code: '',
            type: '', // possible values "error" or "warning"
            display: '', // possible values based on "inline","page","banner"
          },
        ],
        custom: {
          variables: {},
          events: '',
        },
      });
    }
  };

  const handleScreenshot = async (event) => {
    const { imageData } = event.detail;
    const rawImage = imageData;
    const useCompression = true;

    if (store.app.scanState.value === 'found') {
      console.log('GIFT ALREADY FOUND, ABORTING SCAN');
      return;
    }

    try {
      if (!store.app.scanInFlight.value) {
        store.app.scanInFlight.value = true;

        // Compress image
        const finalImage = useCompression
          ? await compressImage(rawImage, 512)
          : rawImage;

        // Create promises for both detection methods
        const visionPromise = detectGift({
          image: finalImage,
          useVision: true,
        });
        const labelPromise = detectGift({
          image: rawImage,
          useVision: false,
        });

        // Get first response
        const response = await Promise.race([
          visionPromise.then((result) => ({ type: 'vision', result })),
          labelPromise.then((result) => ({ type: 'label', result })),
        ]);

        // if (store.app.scanState.value === 'notFound') {
        //   console.log('GIFT NOT FOUND, ABORTING SCAN');
        //   return;
        // }

        // Check first response
        if (response.type === 'vision') {
          if (parseVisionResponse(response.result)) {
            setProductToFound();
            return;
          }
        } else if (customLabelGiftFound(response.result)) {
          setProductToFound();
          return;
        }

        // If first response was negative, wait for second one
        const remainingPromise =
          response.type === 'vision' ? labelPromise : visionPromise;
        const secondResponse = await remainingPromise;

        // Check second response
        const isSecondMethodSuccessful =
          response.type === 'vision'
            ? customLabelGiftFound(secondResponse)
            : parseVisionResponse(secondResponse);

        if (isSecondMethodSuccessful) {
          setProductToFound();
        }
      }
    } catch (error) {
      console.error('Error during gift detection:', error);
    } finally {
      store.app.scanInFlight.value = false;
    }
  };

  const handleProductFound = () => {
    // store.app.scanState.value = 'found';
    if (store.app.productNotFoundTimeoutId.value) {
      clearTimeout(store.app.productNotFoundTimeoutId);
      store.app.productNotFoundTimeoutId.value = false;
    }
  };

  const setLocale = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get('lang');
    const productId = urlParams.get('productId');
    const couponCode = urlParams.get('couponCode');
    const couponCodesActive = urlParams.get('couponCodesActive');
    const useVision = urlParams.get('useVision');

    if (lang) {
      store.app.userLanguage.value = lang;
    } else if (
      window.location.hostname === 'ar.bmoemballezpourlebien.com' ||
      window.location.hostname === 'bmodeballezpourlebien.com'
    ) {
      store.app.userLanguage.value = 'fr';
    } else {
      const userPreferredLanguage = navigator.language;
      if (userPreferredLanguage && userPreferredLanguage.includes('en')) {
        store.app.userLanguage.value = 'en';
      } else if (
        userPreferredLanguage &&
        userPreferredLanguage.includes('fr')
      ) {
        store.app.userLanguage.value = 'fr';
      }
    }

    if (productId) {
      store.app.productIdOverride.value = productId;
    }

    if (couponCode) {
      store.app.couponCodeOverride.value = couponCode;
    }

    if (couponCodesActive === 'false') {
      console.log('Coupon codes active', couponCodesActive);
      store.app.couponCodesActive.value = 'false';
    }

    if (useVision) {
      store.app.useVision.value = true;
    }
  };

  const handleRestartScanTimer = () => {
    console.log('RESTARTING SCAN TIMER');
    const timeoutId = setTimeout(() => {
      // Code to execute after 15 seconds
      console.log('20 seconds have passed!');
      if (store.app.scanState.value !== 'found') {
        store.app.scanState.value = 'notFound';
      }

      window.dispatchEvent(scanStopEvent);
    }, 20000);
    store.app.productNotFoundTimeoutId.value = timeoutId;
    return () => clearTimeout(timeoutId);
  };

  useMount(() => {
    async function prepareApp() {
      // Set up language
      setLocale();

      // Check if we've stored a user id
      const userId = getUserId();
      if (userId) {
        store.app.userId.value = userId;
      } else {
        setUserId();
      }

      // initUserData();
      // await getProduct({
      //   language: store.app.userLanguage.value,
      //   productId: store.app.productIdOverride.value,
      //   couponCode: store.app.couponCodeOverride.value,
      //   couponCodesActive: store.app.couponCodesActive.value,
      // });

      // Set up event listener
      window.addEventListener('screenshotTaken', handleScreenshot);
      // window.addEventListener('GIFT_BOX__TAPPED', handleGiftBoxTapped);
      window.addEventListener('PRODUCT_FOUND', handleProductFound);
      window.addEventListener('RESTART_SCAN_TIMER', handleRestartScanTimer);

      // await getInvalidationTimestamp();
    }

    prepareApp();
  });

  return <RouterProvider router={router} />;
}

App.propTypes = {
  client: PropTypes.object.isRequired,
};

export default App;
