import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';

import useMount from 'src/hooks/useMount';

import watchCurrentPosition from 'src/utils/watchCurrentPosition';

import store from 'src/store';

export const UserPositionContext = React.createContext();

function UserPositionContextProvider({ children }) {
  const [lng, setLng] = useState();
  const [lat, setLat] = useState();
  const [error, setError] = useState(false);
  const positionRef = useRef({}).current;

  useMount(() => {
    watchCurrentPosition(
      (geolocation) => {
        setLng(geolocation.coords.longitude);
        setLat(geolocation.coords.latitude);
        store.app.userLat.value = geolocation.coords.latitude;
        store.app.userLon.value = geolocation.coords.longitude;
      },
      () => {
        setError(true);
      },
    );
  });

  positionRef.lng = lng;
  positionRef.lat = lat;
  positionRef.error = error;

  const position = useMemo(
    () => ({
      lng,
      lat,
      error,
      positionRef,
    }),
    [error, lat, lng, positionRef],
  );

  return (
    <UserPositionContext.Provider value={position}>
      {children}
    </UserPositionContext.Provider>
  );
}

UserPositionContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default UserPositionContextProvider;
