import classNames from 'classnames';
import PropTypes from 'prop-types';

import useTranslation from 'src/hooks/useTranslation';

import arLogo from 'src/assets/svgs/PoweredBy8thWall.svg';
import roseLogo from 'src/assets/svgs/ROSE.svg';

import {
  footer,
  logos,
  logoLeft,
  logoRight,
  footerCopy,
  fadeIn,
  fadeInSlow,
  marginPositioned,
} from './Footer.module.scss';

function Footer({
  showLogos,
  fadeType,
  className,
  positionWithMargin,
  showText = true,
}) {
  const translations = useTranslation();
  return (
    <div
      className={classNames(
        footer,
        className,
        fadeType && fadeType === 'slow' ? fadeInSlow : fadeIn,
        positionWithMargin ? marginPositioned : '',
      )}
    >
      {showLogos && (
        <div className={logos}>
          <img className={logoLeft} src={roseLogo} alt="ROSE Logo" />
          <img className={logoRight} src={arLogo} alt="8th Wall Logo" />
        </div>
      )}
      {showText && (
        <div className={footerCopy}>
          <p>
            {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
            <a
              href={translations.sections.footer.copy.link1Url}
              target="_blank"
              rel="noreferrer"
            >
              {translations.sections.footer.copy.link1Copy}
            </a>{' '}
            {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
            <a
              href={translations.sections.footer.copy.link2Url}
              target="_blank"
              rel="noreferrer"
            >
              {translations.sections.footer.copy.link2Copy}
            </a>
            .
          </p>
        </div>
      )}
    </div>
  );
}

Footer.propTypes = {
  showLogos: PropTypes.bool,
  fadeType: PropTypes.string,
  className: PropTypes.string,
  positionWithMargin: PropTypes.bool,
  showText: PropTypes.bool,
};

export default Footer;
