import PropTypes from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';

import Button from '../Button';

import useTranslation from 'src/hooks/useTranslation';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import store from 'src/store';

import {
  linkOutModalShade,
  linkOutModalWrapper,
  linkOutModalCard,
  modalCtaWrap,
  cancelBtn,
  continueBtn,
  termsFooter,
  miniTitle,
  blueText,
} from './LinkOutModal.module.scss';

function LinkOutModal({
  className = '',
  children,
  type,
  onClose,
  onContinue,
  product,
}) {
  const translations = useTranslation();
  const displayContentByType = () => {
    if (children) {
      return children;
    }
    if (type) {
      if (type === 'new-tab') {
        return (
          <>
            <p>
              {store.app.couponCodesActive.value === 'false'
                ? translations.sections.modals.copy.newTabModalP1PreNoCode
                : translations.sections.modals.copy.newTabModalP1Pre}{' '}
              {product.business_name}
              {store.app.couponCodesActive.value === 'false' &&
              store.app.userLanguage.value === 'fr'
                ? '.'
                : ' '}
              {store.app.couponCodesActive.value !== 'false'
                ? translations.sections.modals.copy.newTabModalP1Post
                : translations.sections.modals.copy.newTabModalP1PostNoCode}
            </p>
            <p>
              {store.app.couponCodesActive.value !== 'false'
                ? translations.sections.modals.copy.newTabModalP2
                : translations.sections.modals.copy.newTabModalP2NoCode}
            </p>
            <div className={modalCtaWrap}>
              <Button onClick={onClose} className={cancelBtn} secondary>
                {translations.sections.modals.copy.newTabModalCancelCta}
              </Button>
              <Button onClick={onContinue} className={continueBtn}>
                {translations.sections.modals.copy.newTabModalContinueCta}
              </Button>
            </div>
          </>
        );
      }
      if (type === 'discover-more') {
        return (
          <>
            <p>{translations.sections.modals.copy.newTabModalP1Bmo}</p>
            <p>{translations.sections.modals.copy.discoverMoreModalP2NoCode}</p>
            <div className={modalCtaWrap}>
              <Button onClick={onClose} className={cancelBtn} secondary>
                {translations.sections.modals.copy.newTabModalCancelCta}
              </Button>
              <Button onClick={onContinue} className={continueBtn}>
                {translations.sections.modals.copy.newTabModalContinueCta}
              </Button>
            </div>
          </>
        );
      }
      if (type === 'new-scan') {
        return (
          <>
            <p className={miniTitle}>
              {translations.sections.modals.copy.newScanModalHeader}
            </p>
            <p>
              {store.app.couponCodesActive.value !== 'false'
                ? translations.sections.modals.copy.newScanModalBody
                : translations.sections.modals.copy.newScanModalBodyNoCode}
            </p>
            <div className={modalCtaWrap}>
              <Button onClick={onClose} className={cancelBtn} secondary>
                {translations.sections.modals.copy.newScanCancelCta}
              </Button>
              <Button onClick={onContinue} className={continueBtn}>
                {translations.sections.modals.copy.newScanContinueCta}
              </Button>
            </div>
            <p className={termsFooter}>
              {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
              <a
                href={translations.sections.footer.copy.link1Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link1Copy}
              </a>{' '}
              {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
              <a
                href={translations.sections.footer.copy.link2Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link2Copy}
              </a>
              .
            </p>
          </>
        );
      }

      if (type === 'reset') {
        return (
          <>
            <p className={miniTitle}>
              {translations.sections.modals.copy.newScanModalHeader}
            </p>
            <p>
              {store.app.couponCodesActive
                ? translations.sections.modals.copy.newScanModalBody
                : translations.sections.modals.copy.newScanModalbodyNoCode}
            </p>
            <div className={modalCtaWrap}>
              <Button onClick={onClose} className={cancelBtn} secondary>
                {translations.sections.modals.copy.newScanCancelCta}
              </Button>
              <Button onClick={onContinue} className={continueBtn}>
                {translations.sections.modals.copy.newScanContinueCta}
              </Button>
            </div>
            <p className={termsFooter}>
              {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
              <a
                href={translations.sections.footer.copy.link1Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link1Copy}
              </a>{' '}
              {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
              <a
                href={translations.sections.footer.copy.link2Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link2Copy}
              </a>
              .
            </p>
          </>
        );
      }
      if (
        type === 'switch-language-en-to-fr' ||
        type === 'switch-language-fr-to-en'
      ) {
        return (
          <>
            <p>{translations.sections.modals.copy.switchLangModalP1}</p>
            <p className={blueText}>
              {translations.sections.modals.copy.switchLangModalP2}
            </p>
            <div className={modalCtaWrap}>
              <Button onClick={onClose} className={cancelBtn} secondary>
                {translations.sections.modals.copy.switchLangModalCancelCta}
              </Button>
              <Button onClick={onContinue} className={continueBtn}>
                {translations.sections.modals.copy.switchLangModalContinueCta}
              </Button>
            </div>
            <p className={termsFooter}>
              {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
              <a
                href={translations.sections.footer.copy.link1Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link1Copy}
              </a>{' '}
              {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
              <a
                href={translations.sections.footer.copy.link2Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link2Copy}
              </a>
              .
            </p>
          </>
        );
      }
    }
    return false;
  };
  return (
    <div className={className}>
      <div className={linkOutModalShade} />
      <div className={linkOutModalWrapper}>
        <div className={linkOutModalCard}>
          {displayContentByType(children, type)}
        </div>
      </div>
    </div>
  );
}

LinkOutModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  product: PropTypes.object,
};

export default LinkOutModal;
