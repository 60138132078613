import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import Footer from '../../components/Footer';
import useMount from '../../hooks/useMount';
import useTranslation from '../../hooks/useTranslation';

import Button from 'src/components/Button';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import reloadSrc from 'src/assets/svgs/reload.svg';
import store from 'src/store';

import {
  permissionsRequiredScreen,
  reload,
  text,
  wrapper,
} from './PermissionsRequiredScreen.module.scss';

function PermissionsRequiredScreen() {
  const translations = useTranslation();
  const handleClick = useCallback(() => {
    window.location.replace(
      `/experience?reloaded=true${
        store.app.userLanguage.value === 'fr' ? '&lang=fr' : ''
      }`,
    );
  }, []);

  useMount(() => {
    dispatchAnalyticsEvent({
      event: 'notify',
      notification: [
        {
          category: 'single',
          code: 'camera permission denied',
          type: 'notification',
          display: 'inline',
        },
      ],
    });
  });

  return (
    <div className={permissionsRequiredScreen}>
      <div className={wrapper}>
        <p className={text}>
          {translations.sections.permissionsRequired.copy.body}
        </p>
        <Button onClick={handleClick} className={reload} leftIcon={reloadSrc}>
          {translations.sections.permissionsRequired.copy.cta}
        </Button>
      </div>
      <Footer showLogos />
    </div>
  );
}

export default PermissionsRequiredScreen;
