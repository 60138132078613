import React from 'react';

import Footer from '../../components/Footer';
import useTranslation from '../../hooks/useTranslation';

import cameraIconSrc from 'src/assets/svgs/camera.svg';
import menuIcon from 'src/assets/svgs/menu.svg';
import reloadIcon from 'src/assets/svgs/reload.svg';

import {
  androidCameraPermissionsErrorScreen,
  cameraIcon,
  text,
  list,
  domain,
  clearReset,
  wrapper,
  headerWrapper,
} from './AndroidCameraPermissionsErrorScreen.module.scss';

function AndroidCameraPermissionsErrorScreen() {
  const translations = useTranslation();
  return (
    <div className={androidCameraPermissionsErrorScreen}>
      <div className={wrapper}>
        <div className={headerWrapper}>
          <img className={cameraIcon} src={cameraIconSrc} alt="Camera icon" />
          <p className={text}>
            {translations.sections.androidCameraError.header}
          </p>
        </div>

        <ol className={list}>
          <li>
            {translations.sections.androidCameraError.list1Pre}{' '}
            <img src={menuIcon} alt="menu" />{' '}
            {translations.sections.androidCameraError.list1Post}
          </li>
          <li>{translations.sections.androidCameraError.list2}</li>
          <li>{translations.sections.androidCameraError.list3}</li>
          <li>{translations.sections.androidCameraError.list4}</li>
          <li>
            <span>
              {translations.sections.androidCameraError.list5} <br />
              <span className={domain}>{window.location.hostname}</span>
            </span>
          </li>
          <li>
            <span className={clearReset}>
              {translations.sections.androidCameraError.list6}
            </span>
          </li>
          <li>
            {translations.sections.androidCameraError.list7Pre}{' '}<img src={reloadIcon} alt="menu" />{' '}
            {translations.sections.androidCameraError.list7Post}
          </li>
        </ol>
      </div>
      <Footer showLogos />
    </div>
  );
}

export default AndroidCameraPermissionsErrorScreen;
