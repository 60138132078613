function watchCurrentPosition(onSuccess, onError) {
  if ('geolocation' in navigator) {
    const watchId = navigator.geolocation.watchPosition(
      (success) => {
        onSuccess(success);
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.warn(error);
        if (onError) {
          onError(error);
        }
      },
      { enableHighAccuracy: true },
    );

    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }

  return () => {};
}

export default watchCurrentPosition;
