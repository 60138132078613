import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  btn,
  btnSecondary,
  btnDisabled,
  btnIconContainer,
  btnLeftIconContainer,
  btnRightIconContainer,
  btnCapitalize,
  btnNoInlinePadding,
  icon,
} from './Button.module.scss';

function Button({
  type,
  children,
  secondary,
  leftIcon,
  rightIcon,
  disabled,
  onClick,
  className,
  disabledLook,
  capitalize,
  noInlinePadding,
  ...props
}) {
  return (
    <button
      disabled={disabled}
      className={classNames(
        btn,
        {
          [btnSecondary]: secondary,
          [btnDisabled]: disabledLook,
          [btnCapitalize]: capitalize,
          [btnNoInlinePadding]: noInlinePadding,
        },
        className,
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      {...props}
    >
      {leftIcon && (
        <div className={classNames(btnIconContainer, btnLeftIconContainer)}>
          <img className={icon} src={leftIcon} alt="button left icon" />
        </div>
      )}
      {children}
      {rightIcon && (
        <div className={classNames(btnIconContainer, btnRightIconContainer)}>
          <img className={icon} src={rightIcon} alt="button left icon" />
        </div>
      )}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  secondary: PropTypes.bool,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  disabled: PropTypes.bool,
  disabledLook: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  capitalize: PropTypes.bool,
  noInlinePadding: PropTypes.bool,
};

export default Button;
