/* eslint-disable no-underscore-dangle */
function dispatchAnalyticsEvent(event) {
  if (window.adobeDataLayer) {
    window.adobeDataLayer.push(event);
  } else {
    console.warn('Adobe Analytics not initialized');
  }

  if (import.meta.env.ENV_NAME !== 'prod') {
    console.log(event, JSON.stringify(event, null, 2));
  }
}

export default dispatchAnalyticsEvent;
