import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button';

import useTranslation from 'src/hooks/useTranslation';

import {
  permissionCard,
  modalBodyContainer,
  modalCtaContainer,
  permissionsBtn,
} from './PermissionsCard.module.scss';

function PermissionsCard({ onContinue, onCancel }) {
  const translations = useTranslation();
  return (
    <div className={permissionCard}>
      <div className={modalBodyContainer}>
        <p>{translations.sections.permissionsPrompt.copy.body}</p>
      </div>
      <div className={modalCtaContainer}>
        <Button onClick={onCancel} className={permissionsBtn} secondary>
          {translations.sections.permissionsPrompt.copy.buttonDenyCopy}
        </Button>
        <Button onClick={onContinue} className={permissionsBtn}>
          {translations.sections.permissionsPrompt.copy.buttonAllowCopy}
        </Button>
      </div>
    </div>
  );
}

PermissionsCard.propTypes = {
  onContinue: PropTypes.func,
  onCancel: PropTypes.func,
};

export default PermissionsCard;
