
      import * as THREE from 'three';
      import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
      import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
      import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
      import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
      import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
      import { TexturePass } from 'three/addons/postprocessing/TexturePass.js';
      import { AfterimagePass } from 'three/addons/postprocessing/AfterimagePass.js';
      import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';

      window.THREE = { ...THREE };
      window.THREE.GLTFLoader = GLTFLoader;
      window.THREE.EffectComposer = EffectComposer;
      window.THREE.RenderPass = RenderPass;
      window.THREE.GlitchPass = GlitchPass;
      window.THREE.UnrealBloomPass = UnrealBloomPass;
      window.THREE.TexturePass = TexturePass;
      window.THREE.AfterimagePass = AfterimagePass;
      window.THREE.ShaderPass = ShaderPass;
      window.THREE.Math = window.THREE.MathUtils;
      window.dispatchEvent(new CustomEvent('threeloaded'));
    