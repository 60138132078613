import PropTypes from 'prop-types';

import useTranslation from '../../hooks/useTranslation';

import rotateanim from './assets/rotateicon.png';

import './index.scss';

function RotateCurtain({ whitelist }) {
  const isOnWhiteList = whitelist?.includes(window.location.pathname);
  const translations = useTranslation();

  return !isOnWhiteList ? (
    <div className="rotate-curtain">
      <img src={rotateanim} className="rotate-icon" alt="rotate phone" />
      <span>{translations.sections.rotatePhoneView.copy.p1Copy}</span>
      <span>{translations.sections.rotatePhoneView.copy.p2Copy}</span>
    </div>
  ) : null;
}

RotateCurtain.propTypes = {
  whitelist: PropTypes.arrayOf(PropTypes.string),
};

export default RotateCurtain;
