const { dataLayer: gtmDataLayer } = window;

const { VITE_MOCK_DATA_LAYERS } = import.meta.env;

export const dataLayer =
  VITE_MOCK_DATA_LAYERS === 'true'
    ? {
        push: (val) => {
          console.log(val);
        },
      }
    : gtmDataLayer || {
        push: () => {
          console.warn('No GTM dataLayer');
        },
      };
