import PropTypes from 'prop-types';

import useMount from 'src/hooks/useMount';

function EventEmitter({ children, onMountEvent, onUnmountEvent }) {
  useMount(() => {
    if (onMountEvent) {
      onMountEvent.emit();
    }

    return () => {
      if (onUnmountEvent) {
        onUnmountEvent.emit();
      }
    };
  });

  return children;
}

EventEmitter.propTypes = {
  children: PropTypes.node.isRequired,
  onMountEvent: PropTypes.shape({
    emit: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }),
  onUnmountEvent: PropTypes.shape({
    emit: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }),
};

export default EventEmitter;
