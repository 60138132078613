import classNames from 'classnames';
import PropTypes from 'prop-types';

import useTranslation from 'src/hooks/useTranslation';

import arLogo from 'src/assets/svgs/PoweredBy8thWall.svg';
import roseLogo from 'src/assets/svgs/ROSE.svg';

import {
  footer,
  logos,
  logoLeft,
  logoRight,
  footerCopy,
} from './DesktopFooter.module.scss';

function DesktopFooter({ showLogos, className }) {
  const translations = useTranslation();
  return (
    <div className={classNames(footer, className)}>
      <div className={footerCopy}>
        <p>
          {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
          <a
            href={translations.sections.footer.copy.link1Url}
            target="_blank"
            rel="noreferrer"
          >
            {translations.sections.footer.copy.link1Copy}
          </a>{' '}
          {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
          <a
            href={translations.sections.footer.copy.link2Url}
            target="_blank"
            rel="noreferrer"
          >
            {translations.sections.footer.copy.link2Copy}
          </a>
          .
        </p>
      </div>
      {showLogos && (
        <div className={logos}>
          <img className={logoLeft} src={roseLogo} alt="ROSE Logo" />
          <img className={logoRight} src={arLogo} alt="8th Wall Logo" />
        </div>
      )}
    </div>
  );
}

DesktopFooter.propTypes = {
  showLogos: PropTypes.bool,
  className: PropTypes.string,
};

export default DesktopFooter;
