import { createNanoEvents } from 'nanoevents';

const emitter = createNanoEvents();

function createEvent(eventName) {
  const event = Symbol(eventName || 'generic-event');

  const subscriptions = [];

  return {
    emit: (...data) => emitter.emit(event, ...data),
    subscribe: (callback) => {
      const unsubscribe = emitter.on(event, callback);

      subscriptions.push({
        callback,
        unsubscribe,
      });

      return () => {
        const subscriptionIndex = subscriptions.findIndex(
          (sub) => sub.callback === callback,
        );

        if (subscriptionIndex > -1) {
          subscriptions.splice(subscriptionIndex, 1);
        }

        unsubscribe();
      };
    },
    unsubscribe: (callback) => {
      const subscriptionIndex = subscriptions.findIndex(
        (sub) => sub.callback === callback,
      );

      if (subscriptionIndex > -1) {
        subscriptions[subscriptionIndex].unsubscribe();
        subscriptions.splice(subscriptionIndex, 1);
      }
    },
  };
}

export default createEvent;
