import PropTypes from 'prop-types';
// import { QRCodeSVG } from 'qrcode.react';
import React, { useMemo } from 'react';
import { QR } from 'react-qr-rounded';

import DesktopFooter from '../DesktopFooter';

import useTranslation from 'src/hooks/useTranslation';

import bmoLogo from 'src/assets/svgs/logo-bmo.svg';
import store from 'src/store';

import styles from './DesktopCurtain.module.scss';

function DesktopCurtain({ whitelist }) {
  const isDesktop = useMemo(() => {
    const hasTouchScreen = 'ontouchstart' in document.documentElement;
    const hasMultipleTouches = navigator.maxTouchPoints > 1;

    return !hasTouchScreen || !hasMultipleTouches;
  }, []);

  const isOnWhiteList = whitelist?.includes(window.location.pathname);
  const translations = useTranslation();
  const isProd = import.meta.env.ENV_NAME === 'prod';

  return isDesktop && !isOnWhiteList ? (
    <div className={styles.desktopCurtainComponent}>
      <div className={styles.logoWrap}>
        <img src={bmoLogo} alt="BMO Logo" />
      </div>
      <div className={styles.bodyWrap}>
        <img
          src={translations.sections.desktopLandingPage.images.heroImage}
          alt="BMO Logo Desktop EN"
          className={
            store.app.userLanguage.value === 'fr' ? styles.frHeroPadding : ''
          }
        />
        <QR className={styles.qrcode} rounding={100}>
          {!isProd // eslint-disable-line no-nested-ternary
            ? window.location.href
            : store.app.userLanguage.value === 'fr'
            ? 'https://qrco.de/bfVS7r'
            : 'https://qrco.de/bfVS7f'}
        </QR>

        <p className={styles.text}>
          {store.app.couponCodesActive.value !== 'false'
            ? translations.sections.desktopLandingPage.copy.bodyP1
            : translations.sections.desktopLandingPage.copy.bodyP1NoCode}
        </p>
        <p className={styles.desktopCtaText}>
          {store.app.couponCodesActive.value
            ? translations.sections.desktopLandingPage.copy.bodyP2
            : translations.sections.desktopLandingPage.copy.bodyP2NoCode}
        </p>
      </div>
      <div className={styles.footerWrap}>
        <DesktopFooter showLogos className={styles.footerBody} />
      </div>
    </div>
  ) : null;
}

DesktopCurtain.propTypes = {
  whitelist: PropTypes.arrayOf(PropTypes.string),
};

export default DesktopCurtain;
