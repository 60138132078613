import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

// import useNavigateTo from '../../hooks/useNavigateTo';
import Button from '../Button';

import useMount from 'src/hooks/useMount';
import useTranslation from 'src/hooks/useTranslation';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import scanSrc from 'src/assets/images/scan-white.png';
import binocularsSrc from 'src/assets/svgs/binoculars.svg';
import bookmarkSrc from 'src/assets/svgs/bookmark.svg';
import copySrc from 'src/assets/svgs/copy.svg';
import giftSrc from 'src/assets/svgs/gift.svg';
import store from 'src/store'; // eslint-disable-line

import {
  productCardWrapper,
  productCardTitle,
  productCardTitleRedeemed,
  productCardHasCodeWrapper,
  couponCodeWrapper,
  couponCodeFirstLine,
  couponCodeText,
  couponCodeMainText,
  couponCode,
  copyIcon,
  disclaimer,
  ctaWrapper,
  saveForLaterBtn,
  productBtnRedeemed,
  productCardNoCodeWrapper,
  productInnerWrapper,
  ctaNoCodeWrapper,
  productCardWithCodeRedeemedWrapper,
  productCardFooter,
  productCardFooterText,
  productCardBodyText,
  shopNowBtn,
  productCardWithCodeRedeemedCodeWrapper,
  codeRedeemedDisclaimer,
  productCardRedeemedPara,
  productCardRedeemedLink,
} from './ProductCard.module.scss';

function ProductCard({ product }) {
  const [codeIsCopied, setCodeIsCopied] = useState(false);
  const translations = useTranslation();
  // const navigateTo = useNavigateTo();

  const copyCode = async () => {
    console.log('Copying code');
    await navigator.clipboard.writeText(product.couponCode);
    setCodeIsCopied(true);

    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'specific',
          details: 'copy promo code--page',
          region: 'page',
        },
      ],
    });

    setTimeout(() => {
      setCodeIsCopied(false);
    }, 2000);
  };

  useMount(() => {
    navigator.clipboard.writeText(product.couponCode);
  });

  // const saveForLater = async () => {
  //   console.log('Saving for later');
  //   const imageUrl = `https://d1sg2qphj2ce8c.cloudfront.net/share-images/${product.product_id}-${store.app.userLanguage.value}-${product.couponCode}.png`;

  //   try {
  //     const response = await fetch(imageUrl);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `${product.product_name}.png`;
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //     window.URL.revokeObjectURL(url);
  //   } catch (err) {
  //     console.error('Error downloading image', err);
  //   }
  // };

  const saveForLater = async () => {
    console.log('Saving for later');
    // url: `https://emkekwiqg8.execute-api.us-east-1.amazonaws.com/dev/share-product?productId=${product.product_id}&productURL=${product.optional_field_2}&couponCode=${product.couponCode}&lang=${store.app.userLanguage.value}`
    const imageUrl = `https://d1sg2qphj2ce8c.cloudfront.net/share-images/${product.product_id}-${store.app.userLanguage.value}-${product.couponCode}.png`;

    try {
      // Fetch the image as a blob
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();

      // Create a file from the blob
      const file = new File(
        [blob],
        `${
          store.app.userLanguage.value === 'fr'
            ? `Vous avez déballé ${product.couponValue} $ de rabais`
            : `You unwrapped $${product.couponValue} off`
        }.jpg`,
        {
          type: blob.type,
        },
      );

      // Prepare share data
      const shareData = {
        title: '',
        text: '',
        files: [file],
      };

      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'save for later--page',
            region: 'page',
          },
        ],
      });
      // Use the Web Share API to share the file
      await navigator.share(shareData);
    } catch (err) {
      console.error('Error sharing content', err);
    }
  };

  const redeem = () => {
    const url = product.optional_field_2.startsWith('https://')
      ? product.optional_field_2
      : `https://${product.optional_field_2}`;
    const modalVariables = {
      url,
      businessName: product.business_name,
    };

    // Update Store
    store.app.modalVariables.value = modalVariables;
    store.app.modalShow.value = true;
    store.app.modalType.value = 'new-tab';

    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'specific',
          details: 'redeem--page',
          region: 'page',
        },
      ],
    });

    // Store redeemed products
    // const redeemed = store.app.userProductsRedeemed.value;
    // console.log('REDEEMED', redeemed);
    // redeemed.push(product.product_id);
    // console.log('NEW REDEEMED', redeemed);
    // store.app.userProductsRedeemed.value = redeemed;
  };

  const showModal = (type) => {
    store.app.modalType.value = type;
    store.app.modalShow.value = true;

    if (type === 'new-scan') {
      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'scan another gift--page',
            region: 'page',
          },
        ],
      });
    }
  };

  const redeemedProducts = store.app.userProductsRedeemed.value;
  const productIsRedeemed = redeemedProducts.includes(product.product_id); // USE PROVIDER AND STORE IN LOCAL STORATE - store.app.productsRedeemed.value
  return (
    <div className={productCardWrapper}>
      {product && product.couponCode && !productIsRedeemed && (
        <div className={productCardHasCodeWrapper}>
          <h2 className={productCardTitle}>
            {translations.sections.product2DView.copy.header}
          </h2>
          <div className={couponCodeWrapper}>
            <div className={couponCodeFirstLine}>
              <div className={couponCodeMainText}>
                {store.app.userLanguage.value === 'fr' ? (
                  <p>
                    {translations.sections.product2DView.copy.preAmountCopy}
                    {product.couponValue}
                    {' $'}
                    {translations.sections.product2DView.copy.postAmountCopy}
                  </p>
                ) : (
                  <p>
                    {translations.sections.product2DView.copy.preAmountCopy} $
                    {product.couponValue}{' '}
                    {translations.sections.product2DView.copy.postAmountCopy}
                  </p>
                )}
              </div>
              <div
                className={couponCode}
                onClick={copyCode}
                role="button"
                tabIndex="0"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    copyCode();
                  }
                }}
              >
                <img className={copyIcon} src={copySrc} alt="Copy Icon" />
                <p className={couponCodeText}>
                  {codeIsCopied
                    ? translations.sections.product2DView.copy.copied
                    : product.couponCode}
                </p>
              </div>
            </div>

            <div className={disclaimer}>
              <p>{translations.sections.product2DView.copy.disclaimer}</p>
            </div>
          </div>
          <div className={ctaWrapper}>
            <Button
              capitalize
              noInlinePadding
              onClick={saveForLater}
              leftIcon={bookmarkSrc}
              className={classNames(saveForLaterBtn)}
            >
              {translations.sections.product2DView.copy.saveCtaCopy}
            </Button>
            <Button
              capitalize
              noInlinePadding
              onClick={redeem}
              leftIcon={giftSrc}
            >
              {translations.sections.product2DView.copy.redeemCtaCopy}
            </Button>
          </div>
        </div>
      )}
      {product && !product.couponCode && !productIsRedeemed && (
        <div className={productCardNoCodeWrapper}>
          <h2 className={productCardTitle}>
            {translations.sections.product2DView.copy.header}
          </h2>
          <div className={productInnerWrapper}>
            <p className={productCardBodyText}>
              {translations.sections.product2DView.copy.bodyNoCode}
            </p>
          </div>
          <div className={ctaNoCodeWrapper}>
            <Button
              capitalize
              noInlinePadding
              leftIcon={giftSrc}
              className={shopNowBtn}
              onClick={() => showModal('new-tab')}
            >
              {translations.sections.product2DView.copy.shopNowCtaCopy}
            </Button>
            <Button
              capitalize
              noInlinePadding
              leftIcon={binocularsSrc}
              onClick={() => showModal('discover-more')}
            >
              {translations.sections.product2DView.copy.discoverMoreCtaCopy}
            </Button>
          </div>
          <div className={productCardFooter}>
            <p className={productCardFooterText}>
              {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
              <a
                href={translations.sections.footer.copy.link1Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link1Copy}
              </a>{' '}
              {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
              <a
                href={translations.sections.footer.copy.link2Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link2Copy}
              </a>
              .
            </p>
          </div>
        </div>
      )}
      {/* } product && product.couponCode && productIsRedeemed && */}
      {product && product.couponCode && productIsRedeemed && (
        <div className={productCardWithCodeRedeemedWrapper}>
          <div className={productCardWithCodeRedeemedCodeWrapper}>
            <p className={productCardRedeemedPara}>
              {translations.sections.product2DView.copy.postRedeemPre}{' '}
              <span>{product.couponCode}</span>{' '}
              {translations.sections.product2DView.copy.postRedeemPost}
            </p>
            <p className={productCardRedeemedLink}>
              <a
                href={
                  product.optional_field_2.startsWith('https://')
                    ? product.optional_field_2
                    : `https://${product.optional_field_2}`
                }
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  dispatchAnalyticsEvent({
                    event: 'click',
                    userEvent: [
                      {
                        category: 'specific',
                        details: `vendor site - ${product.optional_field_2}--page`,
                        region: 'page',
                      },
                    ],
                  });
                  return true;
                }}
              >
                {product.optional_field_2}
              </a>
            </p>
            <p className={codeRedeemedDisclaimer}>
              {translations.sections.product2DView.copy.disclaimer}
            </p>
          </div>
          <h2
            className={classNames(productCardTitle, productCardTitleRedeemed)}
          >
            {translations.sections.product2DView.copy.postRedeemCtaHeader}
          </h2>
          <Button
            capitalize
            leftIcon={scanSrc}
            className={productBtnRedeemed}
            onClick={() => showModal('new-scan')}
          >
            {translations.sections.product2DView.copy.postRedeemCta}
          </Button>
        </div>
      )}
      {product && !product.couponCode && productIsRedeemed && (
        <div className={productCardWithCodeRedeemedWrapper}>
          <h2
            className={classNames(productCardTitle, productCardTitleRedeemed)}
          >
            {translations.sections.product2DView.copy.postRedeemCtaHeader}
          </h2>
          <Button
            capitalize
            leftIcon={scanSrc}
            className={productBtnRedeemed}
            onClick={() => showModal('new-scan')}
          >
            {translations.sections.product2DView.copy.postRedeemCta}
          </Button>
          <div className={productCardFooter}>
            <p className={productCardFooterText}>
              {translations.sections.footer.copy.bodyPreLinkCopy}{' '}
              <a
                href={translations.sections.footer.copy.link1Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link1Copy}
              </a>{' '}
              {translations.sections.footer.copy.bodyPostLink1Copy}{' '}
              <a
                href={translations.sections.footer.copy.link2Url}
                target="_blank"
                rel="noreferrer"
              >
                {translations.sections.footer.copy.link2Copy}
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.object,
};

export default ProductCard;
