import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import scanActiveIcon from 'src/assets/images/scan-white.png';
import scanIcon from 'src/assets/images/scan.png';
import logo from 'src/assets/svgs/logo-bmo.svg';
// import scanActiveIcon from 'src/assets/svgs/scan-white.svg';
// import scanIcon from 'src/assets/svgs/scan.svg';
import soundOnIcon from 'src/assets/svgs/sound-white.svg';
import soundOffIcon from 'src/assets/svgs/sound.svg';
import store from 'src/store';

import {
  navContainer,
  leftContainer,
  rightContainer,
  langSwitcher,
  lang,
  langActive,
  sound,
  scan,
  show,
  icon,
} from './HeaderNav.module.scss';

function HeaderNav({ scanningActive }) {
  const setLanguage = (language) => {
    // store.app.userLanguage.value = language;
    store.app.modalType.value =
      language === 'fr'
        ? 'switch-language-en-to-fr'
        : 'switch-language-fr-to-en';
    store.app.modalShow.value = true;
    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'global',
          details: 'language toggle--header',
          region: 'header',
        },
      ],
    });
  };

  const toggleSound = () => {
    store.app.soundEnabled.value = !store.app.soundEnabled.value;
    const soundEntities = document.querySelectorAll('[sound]');
    if (soundEntities && soundEntities.length > 1) {
      soundEntities.forEach((entity) => {
        if (store.app.soundEnabled.value === false) {
          entity.components.sound.stopSound();
        } else if (entity.id === 'bgmusic') {
          entity.components.sound.playSound();
        }
      });
    }

    // Log analytics event
    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'specific',
          details: 'sound toggle--header',
          region: 'header',
        },
      ],
    });
  };

  const navigateToScanPage = async () => {
    store.app.modalType.value = 'new-scan';
    store.app.modalShow.value = true;
    // Log analytics event
    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'global',
          details: 'gift finder--header',
          region: 'header',
        },
      ],
    });
  };

  return (
    <div
      className={classNames(navContainer, {
        [show]: store.app.landingPageAnimationIsComplete,
      })}
    >
      <div className={leftContainer}>
        <img src={logo} alt="BMO Logo" />
      </div>
      <div className={rightContainer}>
        <div className={langSwitcher}>
          <button
            type="button"
            className={classNames(lang, {
              [langActive]: store.app.userLanguage.value === 'en',
            })}
            onClick={() => setLanguage('en')}
          >
            EN
          </button>
          <button
            type="button"
            className={classNames(lang, {
              [langActive]: store.app.userLanguage.value === 'fr',
            })}
            onClick={() => setLanguage('fr')}
          >
            FR
          </button>
        </div>
        <div className={sound}>
          <button type="button" onClick={() => toggleSound()}>
            <img
              src={store.app.soundEnabled.value ? soundOnIcon : soundOffIcon}
              alt="Sound Icon"
            />
          </button>
        </div>
        <div className={scan}>
          <button type="button" onClick={navigateToScanPage}>
            <img
              src={scanningActive ? scanActiveIcon : scanIcon}
              alt="Scan Icon"
              className={icon}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

HeaderNav.propTypes = {
  scanningActive: PropTypes.bool,
};

export default HeaderNav;
