import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import eruda from 'eruda';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import outputs from '../amplify_outputs.json';

import App from './App';
import store from './redux/store';

Amplify.configure(outputs);
const config = Amplify.getConfig();
Amplify.configure({
  ...config,
  API: {
    REST: {
      [outputs.custom.API.wtgApi.apiName]: {
        endpoint: outputs.custom.API.wtgApi.endpoint,
        region: outputs.custom.API.wtgApi.region,
        apiName: outputs.custom.API.wtgApi.apiName,
      },
    },
    GraphQL: config.API.GraphQL,
  },
});
const amplifyClient = generateClient();


const SHOW_CONSOLE = import.meta.env.VITE_SHOW_CONSOLE;

if (SHOW_CONSOLE === 'true') {
  eruda.init();
}

Sentry.init({
  dsn: 'https://428c84b3ecef102d02e1003cea0f2b91@o4508258756132864.ingest.us.sentry.io/4508258759409665',
  enabled: import.meta.env.ENV_NAME !== 'prod',
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://qa.ar.bmoemballezpourlebien.com',
    'https://ar.bmoemballezpourlebien.com',
    'https://stage.ar.bmoemballezpourlebien.com',
    'https://dev.ar.bmoemballezpourlebien.com',
    'https://www.ar.bmoemballezpourlebien.com',
    'https://qa.ar.bmowrapthegood.com',
    'https://ar.bmowrapthegood.com',
    'https://stage.ar.bmowrapthegood.com',
    'https://dev.ar.bmowrapthegood.com',
    'https://www.ar.bmowrapthegood.com',
    'https://dev.dg2sgpyu8ldev.amplifyapp.com',
    'https://main.dg2sgpyu8ldev.amplifyapp.com',
    'https://qa.dg2sgpyu8ldev.amplifyapp.com',
    'https://stage.dg2sgpyu8ldev.amplifyapp.com',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <App client={amplifyClient} />
    </Provider>
  </React.StrictMode>,
);
