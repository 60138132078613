import { isMobile } from 'react-device-detect';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Footer from '../../components/Footer';
import HeaderNav from '../../components/HeaderNav';
import LinkOutModal from '../../components/LinkOutModal';
import useNavigateTo from '../../hooks/useNavigateTo';

import useMount from 'src/hooks/useMount';
import useTranslation from 'src/hooks/useTranslation';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import frame1 from 'src/assets/images/splash-frames/frame1.png';
import frame10 from 'src/assets/images/splash-frames/frame10.png';
import frame11 from 'src/assets/images/splash-frames/frame11.png';
import frame12 from 'src/assets/images/splash-frames/frame12.png';
import frame13 from 'src/assets/images/splash-frames/frame13.png';
import frame14 from 'src/assets/images/splash-frames/frame14.png';
import frame15 from 'src/assets/images/splash-frames/frame15.png';
import frame16 from 'src/assets/images/splash-frames/frame16.png';
import frame17 from 'src/assets/images/splash-frames/frame17.png';
import frame18 from 'src/assets/images/splash-frames/frame18.png';
import frame19 from 'src/assets/images/splash-frames/frame19.png';
import frame2 from 'src/assets/images/splash-frames/frame2.png';
import frame20 from 'src/assets/images/splash-frames/frame20.png';
import frame21 from 'src/assets/images/splash-frames/frame21.png';
import frame22 from 'src/assets/images/splash-frames/frame22.png';
import frame23 from 'src/assets/images/splash-frames/frame23.png';
import frame24 from 'src/assets/images/splash-frames/frame24.png';
import frame25 from 'src/assets/images/splash-frames/frame25.png';
import frame26 from 'src/assets/images/splash-frames/frame26.png';
import frame27 from 'src/assets/images/splash-frames/frame27.png';
import frame28 from 'src/assets/images/splash-frames/frame28.png';
import frame29 from 'src/assets/images/splash-frames/frame29.png';
import frame3 from 'src/assets/images/splash-frames/frame3.png';
import frame30 from 'src/assets/images/splash-frames/frame30.png';
import frame31 from 'src/assets/images/splash-frames/frame31.png';
import frame32 from 'src/assets/images/splash-frames/frame32.png';
import frame33 from 'src/assets/images/splash-frames/frame33.png';
import frame34 from 'src/assets/images/splash-frames/frame34.png';
import frame35 from 'src/assets/images/splash-frames/frame35.png';
import frame4 from 'src/assets/images/splash-frames/frame4.png';
import frame5 from 'src/assets/images/splash-frames/frame5.png';
import frame6 from 'src/assets/images/splash-frames/frame6.png';
import frame7 from 'src/assets/images/splash-frames/frame7.png';
import frame8 from 'src/assets/images/splash-frames/frame8.png';
import frame9 from 'src/assets/images/splash-frames/frame9.png';
import store from 'src/store';

import {
  landingPage,
  loadingAnimationStyles,
  bodyCopyStyle,
  bodyContainer,
  buttonContainer,
  bold,
  landingPageModal,
} from './LandingPage.module.scss';

const setAnimationAsComplete = () => {
  store.app.landingPageAnimationIsComplete.value = true;
};

function LandingPage() {
  // useAnimationComplete(1650);
  const navigateTo = useNavigateTo();
  const translations = useTranslation();

  const triggerPermissions = () => {
    let navToLink = `/experience/get-permissions`;
    if (store.app.userLanguage.value === 'fr') {
      navToLink = `${navToLink}?lang=fr`;
    }
    navigateTo(navToLink);
  };

  const closeModal = () => {
    store.app.modalShow.value = false;
  };

  const handleModalConfirm = () => {
    const product = store.app.currentProduct.value;
    if (store.app.modalType.value === 'new-tab') {
      store.app.modalShow.value = false;
      window.open(product.product_url, '_blank');
    }

    if (store.app.modalType.value === 'discover-more') {
      store.app.modalShow.value = false;
      const linkOut =
        store.app.userLanguage.value === 'en'
          ? 'https://bmowrapthegood.com'
          : 'https://bmoemballezpourlebien.com/';
      window.open(linkOut, '_blank');
    }

    if (store.app.modalType.value === 'new-scan') {
      store.app.modalShow.value = false;
      window.location.reload();
    }

    if (store.app.modalType.value === 'switch-language-en-to-fr') {
      store.app.modalShow.value = false;
      const windowQueryParams = window.location.search;
      if (windowQueryParams && windowQueryParams.length > 0) {
        const windowQueryParamsParsed = window.location.search.replace(
          'lang=en',
          '',
        );
        window.open(
          `${window.location.origin}${windowQueryParamsParsed}&lang=fr`,
          '_blank',
        );
      } else {
        window.open(`${window.location.origin}?lang=fr`, '_blank');
      }
    }

    if (store.app.modalType.value === 'switch-language-fr-to-en') {
      store.app.modalShow.value = false;
      const windowQueryParams = window.location.search;
      if (windowQueryParams && windowQueryParams.length > 0) {
        const windowQueryParamsParsed = window.location.search.replace(
          'lang=fr',
          '',
        );
        window.open(
          `${window.location.origin}${windowQueryParamsParsed}&lang=en`,
          '_blank',
        );
      } else {
        window.open(`${window.location.origin}?lang=en`, '_blank');
      }
    }
  };

  useMount(() => {
    const frames = [
      frame1,
      frame2,
      frame3,
      frame4,
      frame5,
      frame6,
      frame7,
      frame8,
      frame9,
      frame10,
      frame11,
      frame12,
      frame13,
      frame14,
      frame15,
      frame16,
      frame17,
      frame18,
      frame19,
      frame20,
      frame21,
      frame22,
      frame23,
      frame24,
      frame25,
      frame26,
      frame27,
      frame28,
      frame29,
      frame30,
      frame31,
      frame32,
      frame33,
      frame34,
      frame35,
    ];
    let currentFrame = 0;

    // Preload all frames
    const preloadImages = frames.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = src;
        }),
    );

    const elementCheck = setInterval(async () => {
      const splashGif = document.getElementById('splash-gif');

      if (splashGif) {
        clearInterval(elementCheck);

        try {
          // Wait for all images to load
          await Promise.all(preloadImages);

          const playNextFrame = () => {
            if (currentFrame >= frames.length) {
              setAnimationAsComplete();
              return;
            }

            splashGif.src = frames[currentFrame];
            currentFrame++;
            setTimeout(playNextFrame, 1000 / 45);
          };

          playNextFrame();
        } catch (error) {
          console.error('Failed to load animation frames:', error);
          setAnimationAsComplete();
        }
      }
    }, 100);

    // Dispatch analytics event
    dispatchAnalyticsEvent({
      event: 'pageView',
      page: {
        language: store.app.userLanguage.value,
        authenticated: 'logged out',
        name: 'bmo:uwtg:ribbon entry',
        channel: 'bmo',
        site: 'bmo:uwtg',
        section1: 'bmo:uwtg:ribbon entry',
        section2: '',
        section3: '',
      },
      user: {
        darid: '',
        sessionid: '',
        customerType: '',
        productLoginType: '',
      },
      products: [],
      environment: {
        platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
          window.navigator.userAgent
        } )`,
        version: '',
      },
      conversion: {
        type: '',
        name: '',
        id: '',
        stage: '',
        applicationid: '',
      },
      notification: [
        {
          code: '',
          type: '', // possible values "error" or "warning"
          display: '', // possible values based on "inline","page","banner"
        },
      ],
      custom: {
        variables: {},
        events: '',
      },
    });

    // Cleanup interval if component unmounts
    return () => clearInterval(elementCheck);
  });

  return (
    <>
      <HeaderNav />
      <div className={landingPage}>
        {!store.app.landingPageAnimationIsComplete.value && (
          <img
            className={loadingAnimationStyles}
            src={frame1}
            alt="Wrap The Good - Loading Intro Animation of a bow untying"
            id="splash-gif"
          />
        )}
        {store.app.landingPageAnimationIsComplete.value &&
          !store.app.getStartedClicked.value && (
            <Card heroLogo={translations.sections.landingPage.images.heroImage}>
              <div className={bodyContainer}>
                <p className={bodyCopyStyle}>
                  {store.app.couponCodesActive.value &&
                  store.app.couponCodesActive.value !== 'false'
                    ? translations.sections.landingPage.copy.bodyPre
                    : translations.sections.landingPage.copy.bodyPreNoCode}
                  <br />
                  <span className={bold}>
                    {translations.sections.landingPage.copy.bodyPost}
                  </span>
                </p>
                <div className={buttonContainer}>
                  <Button onClick={triggerPermissions} capitalize>
                    {translations.sections.landingPage.copy.ctaText}
                  </Button>
                </div>
              </div>
            </Card>
          )}
        {store.app.landingPageAnimationIsComplete.value && (
          <Footer fadeType="slow" showLogos={false} />
        )}
        {store.app.modalShow.value && store.app.modalType.value && (
          <LinkOutModal
            className={landingPageModal}
            type={store.app.modalType.value}
            onClose={closeModal}
            onContinue={handleModalConfirm}
            product={store.app.currentProduct}
          />
        )}
      </div>
    </>
  );
}

export default LandingPage;
