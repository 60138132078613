const handleParticles = {
  init() {
    const trigger = document.querySelector('#gift-box');
    window.addEventListener('GIFT_BOX__TAPPED', () => {
      trigger.setAttribute(
        'particles',
        'isExploding: true; amount: 100; rate: 5;',
      );

      // Set a timeout to turn off the explosion after a short delay, if needed
      setTimeout(() => {
        trigger.setAttribute('particles', 'isExploding: false;');
        trigger.setAttribute('gift-open-particles');
      }, 2500); // Adjust the time as needed
    });
  },
};

export default handleParticles;
