import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import experienceScene from '../../aframe/scenes/scene.html?raw';
import AFrameScene from '../../components/AFrameScene';
import useAPI from '../../hooks/useApi';
import AndroidCameraPermissionsErrorScreen from '../AndroidCameraPermissionsErrorScreen';
import AppleCameraPermissionsErrorScreen from '../AppleCameraPermissionsErrorScreen';
import LoadingScreen from '../LoadingScreen';
import MotionPermissionsErrorScreen from '../MotionPermissionsErrorScreen';
import PermissionsRequiredScreen from '../PermissionsRequiredScreen';
import PromptScreen from '../PromptScreen';
import RuntimeErrorScreen from '../RuntimeErrorScreen';

import ARSkinner from 'src/components/ARSkinner';

import useMount from 'src/hooks/useMount';
import useNavigateTo from 'src/hooks/useNavigateTo';
import useUserPosition from 'src/hooks/useUserPosition';

// import animationPlayer from 'src/aframe/components/animationPlayer';

import { dataLayer } from 'src/utils/gtm';
import watchPermissions from 'src/utils/watchPermissions';

import {
  realityReady as realityReadyEvent,
  cameraPermissionsProvided,
  locationPermissionsProvided,
} from 'src/constants/analyticsEvents';

import store from 'src/store';

import components from './components';

function handleCameraAccessGranted() {
  dataLayer.push({
    event: cameraPermissionsProvided,
  });
}

function ExperienceSceneScreen() {
  const navigateTo = useNavigateTo();
  const { getProduct } = useAPI();
  const [realityReady, setRealityReady] = useState(false);
  const [userRedirected, setUserRedirected] = useState(false);

  const userPosition = useUserPosition();

  const handleGeolocationAccessGranted = () => {
    dataLayer.push({
      event: locationPermissionsProvided,
    });
  };

  const redirectUser = useCallback(() => {
    if (userPosition.error) {
      // navigateTo('/experience/geolocation-permissions');
      console.log('USER POSITION ERROR', userPosition.error);
    }
  }, [navigateTo, userPosition.error]); // eslint-disable-line

  const handleRealityReady = async () => {
    // XR8.pause();
    setRealityReady(true);
    store.app.xrReady.value = true;
    let navToLink = '/experience/scan-gift';
    if (store.app.userLanguage.value === 'fr') {
      navToLink = `${navToLink}?lang=fr`;
    }

    navigateTo(navToLink);
    // Fire a new product fetch query - Stored to the store
    console.log('FIRING GET PRODUCT ON REALITY READY');
    await getProduct();
  };

  useEffect(() => {
    if (realityReady) {
      dataLayer.push({
        event: realityReadyEvent,
      });
    }
  }, [realityReady]);

  useEffect(() => {
    if (realityReady && !userRedirected) {
      if (userPosition.error) {
        redirectUser();
        setUserRedirected(true);
      }
    }
  }, [
    realityReady,
    userPosition.lat,
    userPosition.lng,
    userPosition.error,
    redirectUser,
    userRedirected,
  ]);

  useMount(() => {
    watchPermissions({
      onCameraGranted: handleCameraAccessGranted,
      onGeolocationGranted: handleGeolocationAccessGranted,
    });
  });

  return (
    <>
      <AFrameScene
        sceneHtml={experienceScene}
        components={components}
        onRealityReady={handleRealityReady}
        onRealityError={() => {}}
      />
      <ARSkinner
        promptScreen={PromptScreen}
        permissionsErrorScreen={PermissionsRequiredScreen}
        loadingScreen={LoadingScreen}
        androidPermissionsErrorScreen={AndroidCameraPermissionsErrorScreen}
        motionPermissionsErrorScreen={MotionPermissionsErrorScreen}
        runtimeErrorScreen={RuntimeErrorScreen}
      />
      <Outlet />
    </>
  );
}

export default ExperienceSceneScreen;
