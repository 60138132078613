function watchPermissions({ onCameraGranted, onGeolocationGranted }) {
  const watchCameraPermissions = () => {
    navigator?.permissions?.query({ name: 'camera' })?.then((result) => {
      if (result.state === 'granted') {
        onCameraGranted();
      } else {
        setTimeout(watchCameraPermissions, 1000);
      }
    });
  };

  const watchLocationPermissions = () => {
    const watchId = navigator?.geolocation?.watchPosition(
      () => {
        onGeolocationGranted();
        navigator.geolocation.clearWatch(watchId);
      },
      () => {},
      { enableHighAccuracy: true },
    );
  };

  if (onCameraGranted) {
    watchCameraPermissions();
  }

  if (navigator.geolocation && onGeolocationGranted) {
    watchLocationPermissions();
  }
}

export default watchPermissions;
