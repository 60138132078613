const { THREE } = window;

const RECENTER_INTERVAL = 1000;
const RECENTER_THRESHOLD = 0.2;
const CAMERA_VERTICAL_POSITION = 1.6;

const fixRelativeScaleComponent = {
  init() {
    this.scene = this.el.sceneEl;
    this.camera = document.getElementById('camera');

    this.lastUpdate = 0;
    this.cameraPosition = new THREE.Vector3();
  },
  tick(time) {
    if (time > this.lastUpdate + RECENTER_INTERVAL) {
      this.lastUpdate = time;

      this.camera.object3D.getWorldPosition(this.cameraPosition);

      if (
        Math.abs(this.cameraPosition.y - CAMERA_VERTICAL_POSITION) >
        RECENTER_THRESHOLD
      ) {
        this.scene.emit('recenter');
      }
    }
  },
  remove() {},
};

export default fixRelativeScaleComponent;
