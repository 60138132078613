import { useEffect, useState } from 'react';

import useMount from 'src/hooks/useMount';

// import store from 'src/store';

const { AFRAME } = window;
const { XR8 } = window;

// Helper function to make sure that aframe components are only registered once, since they can't
// be cleanly unregistered.
const registeredComponents = new Set();
const registerComponents = (components) =>
  components.forEach(({ name, val }) => {
    if (registeredComponents.has(name)) {
      return;
    }
    registeredComponents.add(name);
    AFRAME.registerComponent(name, val);
  });

// Helper function to make sure that aframe primitives are only registered once, since they can't
// be cleanly unregistered.
const registeredPrimitives = new Set();
const registerPrimitives = (primitives) =>
  primitives.forEach(({ name, val }) => {
    if (registeredPrimitives.has(name)) {
      return;
    }
    registeredPrimitives.add(name);
    AFRAME.registerPrimitive(name, val);
  });

const registeredSystems = new Set();
const registerSystems = (systems) =>
  systems.forEach(({ name, val }) => {
    if (registeredSystems.has(name)) {
      return;
    }
    registeredSystems.add(name);
    AFRAME.registerSystem(name, val);
  });

// A react component for loading and unloading an aframe scene. The initial scene contents should
// be specified as an html string in sceneHtml. All props must be specified when the component
// mounts. Updates to props will be ignored.
//
// Optionally, aframe coponents to register for this scene can be passed as [{name, val}] arrays.
// Care is needed here to not define the same component different across scenes, since aframe
// components can't be unloaded.
//
// Optionally imageTargets can be specified to override the set loaded by default.
function AFrameScene({
  sceneHtml,
  imageTargets,
  components,
  primitives,
  systems,
  onRealityReady,
  onRealityError,
}) {
  const [xrReady, setXRReady] = useState(false);
  useMount(() => {
    const configureImageTargets = () => {
      XR8.XrController.configure({ imageTargets });
    };

    if (imageTargets) {
      if (XR8) {
        configureImageTargets();
      } else {
        window.addEventListener('xrloaded', configureImageTargets);
      }
    }
    if (components) {
      registerComponents(components);
    }
    if (primitives) {
      registerPrimitives(primitives);
    }
    if (systems) {
      registerSystems(systems);
    }

    const html = document.getElementsByTagName('html')[0];
    const origHtmlClass = html.className;
    document.body.insertAdjacentHTML('beforeend', sceneHtml);

    // Cleanup
    return () => {
      const ascene = document.getElementsByTagName('a-scene')[0];
      ascene.parentNode.removeChild(ascene);
      html.className = origHtmlClass;
      window.removeEventListener('xrloaded', configureImageTargets);
    };
  });

  useMount(() => {
    const watchWindow = setInterval(() => {
      if (window.XR8 && window.AFRAME && window.XRExtras) {
        setXRReady(true);
        clearInterval(watchWindow);
      }
    }, 200);
  });

  useEffect(() => {
    if (xrReady) {
      window.handleRealityReady = () => {
        onRealityReady();
        delete window.handleRealityReady;
      };
      window.handleRealityError = () => {
        onRealityError();
        delete window.handleRealityError;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xrReady]);
}
const DISABLE_IMAGE_TARGETS = [];

export { DISABLE_IMAGE_TARGETS };

export default AFrameScene;
