import * as API from 'src/services/api';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import store from 'src/store';

// TODO - Set up local storage library to store seen and redeemed products
function useAPI() {
  async function getProduct() {
    const language = store.app.userLanguage.value;
    const latitude = store.app.userLat.value;
    const longitude = store.app.userLon.value;
    const productId = store.app.productIdOverride.value;
    const couponCode = store.app.couponCodeOverride.value;
    const couponCodesActive = store.app.couponCodesActive.value;

    const params = {
      language,
    };

    if (latitude) {
      params.latitude = latitude;
    }

    if (longitude) {
      params.longitude = longitude;
    }

    if (productId) {
      params.productId = productId;
    }

    if (couponCode) {
      params.couponCode = couponCode;
    }

    if (couponCodesActive === 'false') {
      params.couponCodesActive = couponCodesActive;
    }

    try {
      const { response } = await API.getProduct(params);

      const responseParsed = await response;
      const responseJson = await responseParsed.body.json();
      const sanitizeAmpersand = (str) => str.replace(/&amp;/g, '&');
      if (responseJson) {
        // Update the store with the new product
        const sanitizedJson = {
          ...responseJson,
          business_name: sanitizeAmpersand(responseJson.business_name),
          segment: sanitizeAmpersand(responseJson.segment),
        };
        store.app.currentProduct.value = sanitizedJson;

        // TODO Add product seen to record
      }
    } catch (err) {
      console.error('Error Fetching Product', err);
      dispatchAnalyticsEvent({
        event: 'notify',
        userEvent: [
          {
            category: 'single',
            code: 'failed to load product - servicefail',
            type: 'error',
            display: 'page',
          },
        ],
      });
    }
  }

  async function detectGift({ image, useVision }) {
    // Resize image
    // const resizedImage = await pica.resize(from, to);
    try {
      const { response } = await API.detectGift({ image, useVision });
      const responseParsed = await response;
      const responseJson = await responseParsed.body.json();
      // console.log('JSON RESPONSE', responseJson);
      if (
        responseJson &&
        responseJson.customLabels &&
        responseJson.customLabels.length > 0
      ) {
        return responseJson.customLabels;
      }
      if (responseJson && responseJson.visionData) {
        return JSON.parse(responseJson.visionData);
      }
      return null; // Return null if customLabels are not present
    } catch (err) {
      console.error('Error detecting gift', err);
      return null; // Return null in case of an error
    }
  }

  async function createRedemption({ userId, productId, code, codeValue }) {
    try {
      const response = await API.createRedemption({
        userId,
        productId,
        code,
        codeValue,
      });
      const responseParsed = await response;
      // console.log('RESPONSE FROM CREATE REDEMPTION', responseParsed.data);
    } catch (err) {
      console.error('Error creating redemption', err);
    }
  }

  function setUserId() {
    try {
      const uuid = window.crypto.randomUUID();
      localStorage.setItem('bmowtg_user_id', uuid);
      return uuid;
    } catch (err) {
      console.error('Error setting user id', err);
      return false;
    }
  }

  function getUserId() {
    try {
      const uuid = localStorage.getItem('bmowtg_user_id');
      return uuid || false;
    } catch (err) {
      console.error('Error getting user id', err);
      return false;
    }
  }

  return {
    getProduct,
    detectGift,
    createRedemption,
    setUserId,
    getUserId,
  };
}

export default useAPI;
