import randomFromRange from 'src/utils/randomFromRange';

import { animationPlayerEvents } from 'src/events';

const { THREE } = window;

function randomizeTimeScale(clip, min, max) {
  clip.timeScale = randomFromRange(min, max);
}

const animationPlayer = {
  init() {
    this.load = this.load.bind(this);

    this.model = null;
    this.mixer = null;
    this.clipActions = {};

    const model = this.el.getObject3D('mesh');

    if (model) {
      this.load(model);
    } else {
      this.el.addEventListener('model-loaded', (e) => {
        this.load(e.detail.model);
      });
    }

    this.subscriptions = [
      animationPlayerEvents.play.subscribe(
        (name, { randomTimeScale, interval, timeScale } = {}) => {
          const clipAction = this.clipActions[name]?.action;
          if (clipAction) {
            clipAction.paused = false;
            clipAction.play();
            clipAction.clampWhenFinished = true;
            // clipAction.blendMode = THREE.AddictiveAnimationBlendMode;

            if (timeScale) {
              clipAction.timeScale = timeScale;
            }

            if (randomTimeScale) {
              this.clipActions[name].interval = setInterval(
                () =>
                  randomizeTimeScale(
                    clipAction,
                    typeof randomTimeScale?.min === 'number'
                      ? randomTimeScale.min
                      : 0.1,
                    typeof randomTimeScale?.max === 'number'
                      ? randomTimeScale.max
                      : 3,
                  ),
                typeof interval === 'number' ? interval : 500,
              );
            }
          }
        },
      ),
      animationPlayerEvents.stop.subscribe((name) => {
        const clipAction = this.clipActions[name]?.action;
        const interval = this.clipActions[name]?.interval;

        if (clipAction) {
          clipAction.stop();
        }

        if (interval) {
          clearInterval(interval);
        }
      }),

      animationPlayerEvents.pause.subscribe((name) => {
        const clipAction = this.clipActions[name]?.action;
        const interval = this.clipActions[name]?.interval;

        if (clipAction) {
          clipAction.paused = true;
        }

        if (interval) {
          clearInterval(interval);
        }
      }),

      animationPlayerEvents.stopAll.subscribe(() => {
        this.mixer.stopAllAction();
      }),
    ];
  },

  load(model) {
    this.model = model;
    this.mixer = new THREE.AnimationMixer(model);

    const clips = model.animations;

    clips.forEach((clip) => {
      const action = this.mixer.clipAction(clip);
      this.clipActions[clip.name] = { action, interval: null };
    });
  },

  tick(t, dt) {
    if (this.mixer) {
      this.mixer.update(dt / 1000);
    }
  },

  remove() {
    this.subscriptions.forEach((subscription) => {
      subscription();
    });

    const clipActionsKeys = Object.keys(this.clipActions);

    clipActionsKeys.forEach((key) => {
      if (this.clipActions[key]) {
        clearInterval(this.clipActions[key]);
      }
    });
  },
};

export default animationPlayer;
