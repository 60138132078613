const { THREE } = window;
const particleScene = {
  schema: {
    amount: { type: 'number', default: 300 },
    maxRange: { type: 'number', default: 10 },
    minRange: { type: 'number', default: 5 },
    maxHeight: { type: 'number', default: 5 },
    minHeight: { type: 'number', default: 1 },
  },

  init() {
    console.log('Initializing snow generator');
    this.addSnowflakes = this.addSnowflakes.bind(this);
    this.updateParticles = this.updateParticles.bind(this);
    this.geometry = new THREE.BufferGeometry();
    this.textureLoader = new THREE.TextureLoader();
    this.positions = [];
    this.velocities = [];

    // Get gift box world position
    const existingBox = document.querySelector('.gift-box-obj');
    const boxPos = existingBox.getAttribute('position');
    this.giftBoxHeight = 1; // Adjust as per your gift box height

    this.giftBoxPositionX = boxPos.x;
    this.giftBoxPositionY = boxPos.y + this.giftBoxHeight; // Start just above the box
    this.giftBoxPositionZ = boxPos.z;

    // Add the snowflakes
    this.addSnowflakes();
  },

  addSnowflakes() {
    for (let i = 0; i < this.data.amount; i++) {
      // Generate random positions for snowflakes within a defined range
      this.positions.push(
        this.giftBoxPositionX +
          (Math.random() * this.data.maxRange - this.data.minRange),
        this.giftBoxPositionY + Math.random() * this.data.maxHeight, // Start above the box
        this.giftBoxPositionZ +
          (Math.random() * this.data.maxRange - this.data.minRange),
      );

      // Set initial downward velocity for the snowflakes
      this.velocities.push(0, Math.random() * 0.005 + 0.01, 0); // Y velocity for falling
    }

    this.geometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(this.positions, 3),
    );
    this.geometry.setAttribute(
      'velocity',
      new THREE.Float32BufferAttribute(this.velocities, 3),
    );

    const flakeMaterial = new THREE.PointsMaterial({
      size: 0.1,
      map: this.textureLoader.load('/images/snow.png'),
      blending: THREE.AdditiveBlending,
      depthTest: false,
      transparent: true,
      opacity: 0.5,
    });

    this.particles = new THREE.Points(this.geometry, flakeMaterial);
    // console.log('PARTICLES', this.particles);
    this.el.sceneEl.object3D.add(this.particles);
  },

  updateParticles() {
    // console.log('Updating particles', this.data.amount);
    for (let i = 0; i < this.data.amount; i++) {
      const idx = i * 3; // Access index for each particle's position
      this.particles.geometry.attributes.position.array[idx + 1] -=
        this.particles.geometry.attributes.velocity.array[idx + 1]; // Update Y position

      // Reset particle if it falls below a certain height
      if (this.particles.geometry.attributes.position.array[idx + 1] < -1) {
        this.particles.geometry.attributes.position.array[idx] =
          this.giftBoxPositionX +
          (Math.random() * this.data.maxRange - this.data.minRange);
        this.particles.geometry.attributes.position.array[idx + 1] =
          this.giftBoxPositionY + Math.random() * this.data.maxHeight;
        this.particles.geometry.attributes.position.array[idx + 2] =
          this.giftBoxPositionZ +
          (Math.random() * this.data.maxRange - this.data.minRange);
      }
    }
    this.particles.geometry.attributes.position.needsUpdate = true; // Notify Three.js to update the positions
  },

  tick() {
    this.updateParticles();
  },
};

export default particleScene;
