import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { hideContent } from 'src/components/8thWallSkinners/utils';

import useMount from 'src/hooks/useMount';

import 'src/styles/8thwall.scss';

function LoadingScreenSkinner({ Component }) {
  const containerRef = useRef();
  const [containerExists, setContainerExists] = useState(false);

  useMount(() => {
    const container = document.querySelector('#motionPermissionsErrorApple');
    if (container && !containerRef.current) {
      containerRef.current = container;
      setContainerExists(true);

      hideContent(container);
    }
    const observer = new MutationObserver(() => {
      // const container = document.querySelector('#motionPermissionsErrorApple');

      if (container && !containerRef.current) {
        containerRef.current = container;
        setContainerExists(true);

        hideContent(container);

        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return containerExists && containerRef.current
    ? createPortal(<Component />, containerRef.current)
    : null;
}

LoadingScreenSkinner.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default LoadingScreenSkinner;
