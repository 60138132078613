import landingPageEnLogo from 'src/assets/images/title-en.png';
import landingPageFrLogo from 'src/assets/images/title-fr.png';
import landingPageDesktopFrLogo from 'src/assets/images/ugw-logo-desktop-fr.png';
import landingPageDesktopEnLogo from 'src/assets/images/ugw-logo-desktop.png';
import store from 'src/store';

const translations = {
  en: {
    sections: {
      landingPage: {
        images: {
          heroImage: landingPageEnLogo,
        },
        copy: {
          bodyPre:
            'Wrapped gifts are all around you. Now, you can unwrap all of them to reveal promo codes from underrepresented businesses.',
          bodyPost: 'Simply find a wrapped gift with your camera to unwrap it.',
          bodyPreNoCode:
            'Wrapped gifts are all around you. Now, you can unwrap all of them to reveal gifts from underrepresented businesses.',
          ctaText: 'Start Unwrapping',
        },
      },
      desktopLandingPage: {
        images: {
          heroImage: landingPageDesktopEnLogo,
        },
        copy: {
          bodyP1:
            'Wrapped gifts are all around you. Now, you can unwrap all of them to reveal promo codes from underrepresented businesses.',
          bodyP2: 'Scan the QR code on your mobile device to start unwrapping!',
          bodyP1NoCode: `Wrapped gifts are all around you. Now, you can unwrap all of them to reveal gifts from underrepresented businesses.`,
          bodyP2NoCode: `Scan the QR code on your mobile device to start unwrapping!`,
        },
      },
      footer: {
        copy: {
          bodyPreLinkCopy: 'By using this BMO Experience you agree to our',
          link1Copy: 'Terms and Conditions',
          link1Url: 'https://bmowrapthegood.com/',
          bodyPostLink1Copy: 'and',
          link2Copy: 'Privacy Policy',
          link2Url:
            'https://www.bmo.com/main/about-bmo/privacy-security/our-privacy-code/',
        },
      },
      permissionsPrompt: {
        copy: {
          body: 'In order to unwrap gifts and reveal gifts that are available in your area, we will require access to your camera and your location.',
          buttonDenyCopy: 'DENY',
          buttonAllowCopy: 'ALLOW',
        },
      },
      scanningView: {
        copy: {
          instructionsScanning: 'Align your camera with a wrapped gift',
          instructionsNotFound: 'No wrapped gift in sight?',
          instructionsNotFoundCtaCopy: 'Tap here for a gift from us!',
          instructionsNotFoundCtaNoCodesCopy: 'Tap here for help!',
          instructionsTapToPlace: 'Tap to place gift!',
          instructionsFound: 'Gift found!',
          instructionsTap: 'Tap the gift to Unwrap the Good',
        },
      },
      product3DView: {
        copy: {
          couponCodeLine1: 'You unwrapped',
          couponCodeAmountCopy: ' off!',
          productCompanyNamePre: 'FROM: ',
          productCompanyNamePreNoCode: 'YOU UNWRAPPED A GIFT IDEA FROM:',
        },
      },
      product2DView: {
        copy: {
          header: 'Good find!',
          preAmountCopy: 'Your ',
          postAmountCopy: ' off promo code:',
          bodyNoCode:
            'Shop now or discover more gift ideas from underrepresented businesses.',
          disclaimer:
            '*Expires 12/31/2024. See terms and conditions for details.',
          saveCtaCopy: 'Save for later',
          redeemCtaCopy: 'Redeem now',
          shopNowCtaCopy: 'Shop Now',
          discoverMoreCtaCopy: 'Discover More',
          postRedeemPre: 'Use ',
          postRedeemPost: 'at',
          postRedeemDisclaimer:
            '*Expires 12/31/2024. See terms and conditions for details.',
          postRedeemCtaHeader: 'Want to unwrap more gifts?',
          postRedeemCta: 'Scan another gift',
          copied: 'COPIED!',
        },
      },
      termsDrawer: {
        copy: {
          label: 'Read terms and conditions',
          body: 'Discount code expires December 31, 2024 (11:59:59 p.m. ET). Discount: (i) is applied before taxes; (ii) has no cash surrender or other value; (iii) cannot be combined with other offers; (iv) cannot be applied to past transactions; (v) cannot be applied to the purchase of gift cards. Limit one (1) discount per purchase (codes cannot be combined with each other). Pre-tax value of any item(s) purchased using discount must exceed the discount value. Conditions apply.',
        },
      },
      modals: {
        copy: {
          newTabModalP1Pre:
            'If you click continue, you will leave this BMO Experience. A new browser window will open, allowing you to visit the ',
          newTabModalP1PreNoCode:
            'If you click continue, you will leave this BMO Experience. A new browser window will open, allowing you to visit the ',
          newTabModalP1Post: ' website and apply the promo code at checkout.',
          newTabModalP1PostNoCode: ' website.',
          newTabModalP1NoCode:
            'If you click continue, you will leave this BMO Experience. A new browser window will open, allowing you to visit BMOWrapTheGood.com.',
          newTabModalP1Bmo:
            'If you click continue, you will leave this BMO Experience. A new browser window will open, allowing you to visit BMOWrapTheGood.com.',
          newTabModalP2:
            'BMO encourages you to read the privacy statements and legal terms and conditions of all third party sites.',
          newTabModalP2NoCode:
            'BMO encourages you to read the privacy statements and legal terms and conditions.',
          discoverMoreModalP2NoCode:
            'BMO encourages you to read the privacy statements and legal terms and conditions.',
          newTabModalCancelCta: 'Cancel',
          newTabModalContinueCta: 'Continue',
          newScanModalHeader: 'Want to unwrap another gift?',
          newScanModalBody:
            'Clicking continue will reset your experience. Save your current promo code first if you intend to redeem it later.',
          newScanModalBodyNoCode:
            'Clicking continue will reset your experience',
          newScanCancelCta: 'Cancel',
          newScanContinueCta: 'Continue',
          switchLangModalP1: `Si vous cliquez sur continuer, vous passerez à la version française de cette expérience BMO. Le changement de langue redémarrera l'expérience complète et actualisera cette page.`,
          switchLangModalP2:
            'If you click continue, you will switch to the French version of this BMO Experience. Switching languages will restart the full experience and refresh this page.',
          switchLangModalCancelCta: 'Annuler',
          switchLangModalContinueCta: 'Continuer',
        },
      },
      rotatePhoneView: {
        copy: {
          p1Copy: 'Please rotate phone.',
          p2Copy: 'Experience must be viewed in portrait mode.',
        },
      },
      retryPermissions: {
        body: 'In order to unwrap gifts and reveal gifts that are available in your area, we will require access to your camera and your location.',
        cta: 'Retry',
      },
      appleCameraPermissionsError: {
        bodyP1: 'This experience requires access to your device.',
        bodyP2: 'Please accept these permissions to continue.',
        cta: 'Allow Access',
      },
      androidCameraError: {
        header: `Let's enable your camera`,
        list1Pre: 'Tap the',
        list1Post: 'in the top right',
        list2: 'Tap settings',
        list3: 'Site settings',
        list4: 'Camera',
        list5: 'Blocked',
        list6: 'Clear and Reset',
        list7Pre: 'Then,',
        list7Post: 'reload the page to launch the experience',
      },
      androidMicError: {
        header: `Let's enale your microphone`,
        list1Pre: 'Tap the',
        list1Post: 'in the top right',
        list2: 'Tap settings',
        list3: 'Site settings',
        list4: 'Microphone',
        list5: 'Blocked',
        list6: 'Clear and Reset',
        list7Pre: 'Then,',
        list7Post: 'reload the page to launch the experience',
      },
      permissionsRequired: {
        copy: {
          body: 'In order to unwrap gifts and reveal gifts that are available in your area, we will require access to your camera and your location.',
          cta: 'Retry',
        },
      },
      permissionsRequiredMotion: {
        header: 'Permissions were denied.',
        bodyP1:
          'In order to use this experience we will require access to your device’s motion sensors. ',
        bodyP2: 'Please close the app you are using and try again.',
      },
      motionPermissionsError: {
        header: 'Permissions were denied.',
        bodyP1: "You've prevented the page from accessing your motion sensors.",
        bodyP2: 'Please close the app to reenable your motion sensors.',
      },
      runtimeError: {
        header: 'Oops!',
        body: 'It looks like something went wrong.',
        btnCopy: 'Refresh',
      },
    },
    utils: {
      formatCurrency: (value) => `$${value}`,
    },
  },
  fr: {
    sections: {
      landingPage: {
        images: {
          heroImage: landingPageFrLogo,
        },
        copy: {
          bodyPre: `Des cadeaux emballés se trouvent tout autour de vous, et vous pouvez les déballer pour recevoir des codes promo d'entreprises sous-représentées.`,
          bodyPreNoCode:
            "Des cadeaux emballés se trouvent tout autour de vous, et vous pouvez les déballer pour découvrir des cadeaux d'entreprises sous-représentées.",
          bodyPost:
            'Trouvez un cadeau avec votre appareil photo pour le déballer.',
          ctaText: `COMMENCER À DÉBALLER`,
        },
      },
      desktopLandingPage: {
        images: {
          heroImage: landingPageDesktopFrLogo,
        },
        copy: {
          bodyP1: `Des cadeaux emballés se trouvent tout autour de vous, et vous pouvez les\ndéballer pour découvrir des codes promo d'entreprises sous-représentées.`,
          bodyP2: `Balayez le code QR sur votre appareil photo pour commencer à déballer!`,
          bodyP1NoCode: `Des cadeaux emballés se trouvent tout autour de vous, et vous pouvez les\ndéballer pour découvrir des cadeaux d'entreprises sous-représentées.`,
          bodyP2NoCode: `Balayez le code QR sur votre appareil photo pour commencer à déballer !`,
        },
      },
      footer: {
        copy: {
          bodyPreLinkCopy:
            'En prenant part à cette Expérience BMO, vous acceptez nos',
          link1Copy: `Conditions d'utilisation`,
          link1Url: 'https://bmoemballezpourlebien.com',
          bodyPostLink1Copy: 'et notre',
          link2Copy: 'Politique de confidentialité',
          link2Url:
            'https://www.bmo.com/principal/a-propos-de-nous/confidentialite-securite/notre-code-de-confidentialite/',
        },
      },
      permissionsPrompt: {
        copy: {
          body: 'Afin de déballer des cadeaux disponibles dans votre région, nous devons avoir accès à votre appareil photo et à votre emplacement.',
          buttonDenyCopy: 'REFUSER',
          buttonAllowCopy: 'AUTORISER',
        },
      },
      scanningView: {
        copy: {
          instructionsScanning:
            'Aligner votre appareil photo sur\nun cadeau emballé',
          instructionsNotFound: 'Vous ne voyez pas de cadeau emballé?',
          instructionsNotFoundCtaCopy:
            'Appuyer ici pour en recevoir un de notre part!',
          instructionsNotFoundCtaNoCodesCopy: `Appuyer ici pour obtenir de l'aide!`,
          instructionsTapToPlace: 'Appuyez pour placer le cadeau!',
          instructionsFound: 'Cadeau trouvé!',
          instructionsTap: 'Appuyer sur le cadeau pour\nDéballer pour le bien',
        },
      },
      product3DView: {
        copy: {
          couponCodeLine1: 'Vous avez déballé',
          couponCodeAmountCopy: ' de rabais!',
          productCompanyNamePre: 'DE : ',
          productCompanyNamePreNoCode: 'VOUS AVEZ DÉBALLÉ UNE IDÉE-CADEAU DE :',
        },
      },
      product2DView: {
        copy: {
          header: 'Belle trouvaille!',
          preAmountCopy: 'Votre code promo pour un rabais de ',
          postAmountCopy: ' :',
          bodyNoCode:
            "Magasinez ou découvrez d'autres idées-cadeaux d'entreprises sous-représentées.",
          disclaimer:
            "* Expire le 31/12/2024. Voir les Conditions d'utilisation pour les détails.",
          saveCtaCopy: 'ENREGISTRER',
          redeemCtaCopy: 'UTILISER',
          shopNowCtaCopy: 'MAGASINER',
          discoverMoreCtaCopy: 'DÉCOUVRIR',
          postRedeemPre: 'Utiliser ',
          postRedeemPost: 'à',
          postRedeemDisclaimer:
            "* Expire le 31/12/2024. Voir les Conditions d'utilisation pour les détails.",
          postRedeemCtaHeader: 'Vous voulez déballer plus de cadeaux?',
          postRedeemCta: 'BALAYER UN AUTRE CADEAU',
          copied: 'COPIÉ',
        },
      },
      termsDrawer: {
        copy: {
          label: `Lire les Conditions d'utilisation`,
          body: `Le code de réduction expire le 31 décembre 2024 (23:59:59 HE). La réduction : i) s'applique avant les taxes; ii) n'a aucune valeur marchande ou autre valeur; iii) ne peut être combinée avec d'autres offres; iv) ne peut s'appliquer sur des transactions antérieures; et v) ne s'applique pas sur l'achat de cartes-cadeaux. Limite d'une (1) réduction par achat (les codes ne peuvent être combinés avec d'autres codes). La valeur avant les taxes de tout achat effectué en utilisant la réduction doit excéder la valeur de la réduction. Des conditions s'appliquent.`,
        },
      },
      modals: {
        copy: {
          newTabModalP1Pre:
            "Si vous cliquez sur « Continuer », vous quitterez cette Expérience BMO. Une nouvelle fenêtre de navigateur s'ouvrira pour vous permettre de visiter le site Web de ",
          newTabModalP1PreNoCode:
            "Si vous cliquez sur « Continuer », vous quitterez cette Expérience BMO. Une nouvelle fenêtre de navigateur s'ouvrira pour vous permettre de visiter le site Web de ",
          newTabModalP1Post: "et d'appliquer le code promo à la caisse.",
          newTabModalP1Bmo:
            "Si vous cliquez sur « Continuer », vous quitterez cette Expérience BMO. Une nouvelle fenêtre de navigateur s'ouvrira, pour vous permettre de visiter le site BMOEmballezPourLeBien.com.",
          newTabModalP1NoCode:
            "Si vous cliquez sur « Continuer », vous quitterez cette Expérience BMO. Une nouvelle fenêtre de navigateur s'ouvrira pour vous permettre de visiter le site Web de",
          newTabModalP2:
            "BMO vous encourage à lire les politiques de confidentialité et les conditions d'utilisation de tous les sites de tiers.",
          newTabModalP2NoCode:
            "BMO vous encourage à lire les politiques de confidentialité et les conditions d'utilisation de tous les sites de tiers.",
          discoverMoreModalP2NoCode:
            "BMO vous encourage à lire les politiques de confidentialité et les conditions d'utilisation.",
          newTabModalCancelCta: 'ANNULER',
          newTabModalContinueCta: 'CONTINUER',
          newScanModalHeader: 'Vous voulez déballer un autre cadeau?',
          newScanModalBody:
            "En cliquant sur « Continuer », vous réinitialisez votre expérience. Sauvegardez d'abord votre code promo actuel si vous avez l'intention de l'utiliser plus tard.",
          newScanModalBodyNoCode:
            'Cliquez sur « Continuer » pour réinitialiser votre expérience.',
          newScanCancelCta: 'ANNULER',
          newScanContinueCta: 'CONTINUER',
          switchLangModalP1: `If you click continue, you will switch to the English version of this BMO Experience. Switching languages will restart the full experience and refresh this page.`,
          switchLangModalP2:
            "Si vous cliquez sur continuer, vous passerez à la version anglaise de cette expérience BMO. Le changement de langue redémarrera l'expérience complète et actualisera cette page.",
          switchLangModalCancelCta: 'CANCEL',
          switchLangModalContinueCta: 'CONTINUE',
        },
      },
      rotatePhoneView: {
        copy: {
          p1Copy: 'Veuillez faire pivoter votre téléphone.',
          p2Copy: "L'expérience doit être vue en mode portrait.",
        },
      },
      retryPermissions: {
        body: "Pour déballer les cadeaux et révéler ceux qui sont disponibles dans votre région, nous aurons besoin d'accéder à votre caméra et à votre position.",
        cta: 'Réessayer',
      },
      appleCameraPermissionsError: {
        bodyP1: `Cette expérience nécessite l'accès à votre appareil.`,
        bodyP2: 'Veuillez accepter ces autorisations pour continuer.',
        cta: `Autoriser l'accès.`,
      },
      androidCameraError: {
        header: `Activons votre caméra`,
        list1Pre: 'Appuyez sur',
        list1Post: 'en haut à droite',
        list2: 'Appuyez sur Paramètres',
        list3: 'Paramètres du site',
        list4: 'Caméra',
        list5: 'Bloqué',
        list6: 'Effacer et réinitialiser',
        list7Pre: 'Ensuite,',
        list7Post: "rechargez la page pour lancer l'expérience",
      },
      androidMicError: {
        header: `Activons votre microphone`,
        list1Pre: 'Appuyez sur',
        list1Post: 'en haut à droite',
        list2: 'Appuyez sur Paramètres',
        list3: 'Paramètres du site',
        list4: 'Microphone',
        list5: 'Bloqué',
        list6: 'Effacer et réinitialiser',
        list7Pre: 'Ensuite,',
        list7Post: "rechargez la page pour lancer l'expérience",
      },
      permissionsRequired: {
        copy: {
          body: 'Afin de déballer des cadeaux disponibles dans votre région, nous devons avoir accès à votre appareil photo et à votre emplacement.',
          cta: 'Réessayer',
        },
      },
      permissionsRequiredMotion: {
        header: 'Les autorisations ont été refusées.',
        bodyP1:
          'Afin d’utiliser cette expérience, nous avons besoin d’accéder aux capteurs de mouvement de votre appareil.',
        bodyP2: "Veuillez fermer l'application que vous utilisez et réessayer.",
      },
      motionPermissionsError: {
        header: 'Les autorisations ont été refusées.',
        bodyP1:
          "Vous avez empêché la page d'accéder à vos capteurs de mouvement.",
        bodyP2:
          "Veuillez fermer l'application pour réactiver vos capteurs de mouvement.",
      },
    },
    utils: {
      formatCurrency: (value) => `$${value}`,
      interpolate: (values, destinationString) => destinationString,
    },
  },
};

const useTranslation = () => {
  const lang = store.app.userLanguage.value;
  return translations[lang];
};

export default useTranslation;
