import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import EventEmitter from 'src/components/EventEmitter';

import useMount from 'src/hooks/useMount';

import { loadingScreenClosedEvent, loadingScreenOpenedEvent } from 'src/events';

import 'src/styles/8thwall.scss';

function LoadingScreenSkinner({ Component }) {
  const containerRef = useRef();
  const [containerExists, setContainerExists] = useState(false);

  useMount(() => {
    const loadBackground = document.querySelector('#loadBackground');
    if (loadBackground && !containerRef.current) {
      containerRef.current = loadBackground;
      setContainerExists(true);

      loadBackground.innerHTML = '';
    } else if (!loadBackground && containerRef.current) {
      setContainerExists(false);
      containerRef.current = null;
    }
    
    const observer = new MutationObserver(() => {
      if (loadBackground && !containerRef.current) {
        containerRef.current = loadBackground;
        setContainerExists(true);

        loadBackground.innerHTML = '';
      } else if (!loadBackground && containerRef.current) {
        setContainerExists(false);
        containerRef.current = null;
      }
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return containerExists && containerRef.current
    ? createPortal(
        <EventEmitter
          onMountEvent={loadingScreenOpenedEvent}
          onUnmountEvent={loadingScreenClosedEvent}
        >
          <Component />
        </EventEmitter>,
        containerRef.current,
      )
    : null;
}

LoadingScreenSkinner.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default LoadingScreenSkinner;
