import { batch, signal } from '@preact/signals-react';
import { clone } from 'remeda';

function createStore(initialState, doNotReset) {
  const store = clone(initialState);

  const defaultStoreValues = clone(store);

  const storeKeys = Object.keys(store);

  storeKeys.forEach((key) => {
    store[key] = signal(store[key]);
  });

  Object.freeze(store);
  Object.freeze(storeKeys);
  Object.freeze(defaultStoreValues);

  function resetStore() {
    batch(() => {
      storeKeys.forEach((key) => {
        if (!doNotReset.includes(key)) {
          store[key].value = defaultStoreValues[key];
        }
      });
    });
  }

  return [store, resetStore];
}

export default createStore;
