import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import useTranslation from 'src/hooks/useTranslation';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import arrow from 'src/assets/svgs/arrow.svg';
import store from 'src/store';

import {
  termsDrawer,
  termsDrawerHeader,
  termsDrawerHeaderLabel,
  termsDrawerIcon,
  termsDrawerBody,
  termsDrawerText,
  drawerIsOpen,
  drawerParentIsOpen,
  langFr,
} from './TermsDrawer.module.scss';

function TermsDrawer({ className }) {
  const translations = useTranslation();
  const lang = store.app.userLanguage.value;
  const [isOpen, toggleDrawer] = useState(false);

  useEffect(() => {
    if (isOpen) {
      dispatchAnalyticsEvent({
        event: 'click',
        userEvent: [
          {
            category: 'specific',
            details: 'legal toggle--page',
            region: 'page',
          },
        ],
      });
    }
  }, [isOpen]);
  return (
    <div
      className={classNames(termsDrawer, className, {
        [drawerParentIsOpen]: isOpen,
        [langFr]: lang === 'fr',
      })}
    >
      <div
        className={termsDrawerHeader}
        onClick={() => toggleDrawer(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleDrawer(!isOpen);
          }
        }}
        tabIndex={0}
        role="button"
      >
        <p className={termsDrawerHeaderLabel}>
          {translations.sections.termsDrawer.copy.label}
        </p>
        <img
          className={classNames(termsDrawerIcon, { [drawerIsOpen]: isOpen })}
          src={arrow}
          alt="Arrow Icon"
        />
      </div>
      <div className={termsDrawerBody}>
        <p className={termsDrawerText}>
          {translations.sections.termsDrawer.copy.body}
        </p>
      </div>
    </div>
  );
}

TermsDrawer.propTypes = {
  className: PropTypes.string,
};

export default TermsDrawer;
