import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import 'src/styles/8thwall.scss';
import { hideContent } from 'src/components/8thWallSkinners/utils';

import useMount from 'src/hooks/useMount';

function PromptScreenSkinner({ Component }) {
  const promptBoxRef = useRef();
  const continueButtonRef = useRef();
  const cancelButtonRef = useRef();
  const [promptBoxExists, setPromptBoxExists] = useState(false);

  const handleCancel = useCallback(() => {
    cancelButtonRef.current.click();
  }, []);
  const handleContinue = useCallback(() => {
    continueButtonRef.current.click();
  }, []);

  useMount(() => {
    const observer = new MutationObserver(() => {
      const promptBox = document.querySelector('.prompt-box-8w');

      if (promptBox && !promptBoxRef.current) {
        promptBoxRef.current = promptBox;
        // modify class to prevent 8th-wall styling mess with react style
        promptBox.classList.add('prompt-box-8w--modified');
        promptBox.classList.remove('prompt-box-8w');

        const cancelButton = document.querySelector(
          '.prompt-button-8w:not(.button-primary-8w)',
        );
        const continueButton = document.querySelector(
          '.prompt-button-8w.button-primary-8w',
        );

        if (continueButton && cancelButton) {
          continueButtonRef.current = continueButton;
          cancelButtonRef.current = cancelButton;

          setPromptBoxExists(true);
          hideContent(promptBox);
          promptBox.innerHTML = '';

          observer.disconnect();
        }
      }
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return promptBoxExists
    ? createPortal(
        <Component onCancel={handleCancel} onContinue={handleContinue} />,
        promptBoxRef.current,
      )
    : null;
}

PromptScreenSkinner.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default PromptScreenSkinner;
