import createEvent from 'src/utils/createEvent';

export const changeDeviceEvent = createEvent('change-device');
export const changeColorEvent = createEvent('change-color');

export const loadingScreenOpenedEvent = createEvent('loading-screen-opened');
export const loadingScreenClosedEvent = createEvent('loading-screen-closed');

export const placePortalEvent = createEvent('place-portal');

export const pressInfoModalButton = createEvent('press-info-modal-button');
export const animationPlayerEvents = {
  play: createEvent('animation-player_play'),
  stop: createEvent('animation-player_stop'),
  pause: createEvent('animation-player_pause'),
  stopAll: createEvent('animation-player_stop-all'),
};
