import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { card, cardHeroLogo } from './Card.module.scss';

function Card({ heroLogo, ...props }) {
  return (
    <div className={card}>
      {heroLogo && (
        <div className={classNames(cardHeroLogo)}>
          <img src={heroLogo} alt="Wrap the Good Experience Logo" />
        </div>
      )}
      {props.children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  heroLogo: PropTypes.string,
  buttons: PropTypes.array,
  className: PropTypes.string,
};

export default Card;
