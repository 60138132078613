import createStore from 'src/store/createStore';

const appInitialState = {
  landingPageAnimationIsComplete: false,
  getStartedClicked: false,
  experiencePlaced: false,
  soundEnabled: true,
  settingsOpened: false,
  userLanguage: 'en',
  userId: 1,
  userProductsSeen: [],
  userProductsRedeemed: [],
  currentProduct: {},
  xrReady: false,
  userLat: false,
  userLon: false,
  scanState: 'scanning', // scanning, found, notFound, giftBoxTapped
  showInstructionalOverlay: true,
  showProductCard: false,
  scanInFlight: false,
  boxPlaceEnabled: false,
  modalType: 'new-tab',
  modalVariables: false,
  modalShow: false,
  productIdOverride: false,
  couponCodeOverride: false,
  couponCodesActive: true,
  productNotFoundTimeoutId: false,
  useVision: false,
  client: {},
  hasCreatedRedemption: false,
};

const [appStore, resetAppStore] = createStore(appInitialState);

const store = {
  app: appStore,
};

export { resetAppStore };

export default store;
