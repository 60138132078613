import PropTypes from 'prop-types';
import React from 'react';

import { hudWrapper, hudGrow } from './HudWrapper.module.scss';

function HudWrapper({ children }) {
  return <div className={hudWrapper}>{children}</div>;
}

HudWrapper.propTypes = {
  children: PropTypes.node,
};

export function HudGrowWrapper({ children }) {
  return <div className={hudGrow}>{children}</div>;
}

HudGrowWrapper.propTypes = {
  children: PropTypes.node,
};

export default HudWrapper;
