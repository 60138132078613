import PropTypes from 'prop-types';
import React from 'react';

import CameraPermissionsErrorScreenSkinner from 'src/components/8thWallSkinners/CameraPermissionsErrorScreenSkinner';
import LoadingScreenSkinner from 'src/components/8thWallSkinners/LoadingScreenSkinner';
import MicrophonePermissionsErrorScreenSkinner from 'src/components/8thWallSkinners/MicrophonePermissionsErrorScreenSkinner';
import MotionPermissionsErrorScreenSkinner from 'src/components/8thWallSkinners/MotionPermissionsErrorScreenSkinner';
import PromptScreenSkinner from 'src/components/8thWallSkinners/PromptScreenSkinner';
import RuntimeErrorSkinner from 'src/components/8thWallSkinners/RuntimeErrorSkinner';
import UserPromptErrorSkinner from 'src/components/8thWallSkinners/UserPromptErrorSkinner';

function ARSkinner({
  promptScreen,
  permissionsErrorScreen,
  loadingScreen,
  androidPermissionsErrorScreen,
  motionPermissionsErrorScreen,
  runtimeErrorScreen,
}) {
  return (
    <>
      {promptScreen && <PromptScreenSkinner Component={promptScreen} />}
      {permissionsErrorScreen && (
        <UserPromptErrorSkinner Component={permissionsErrorScreen} />
      )}
      {loadingScreen && <LoadingScreenSkinner Component={loadingScreen} />}
      {permissionsErrorScreen && androidPermissionsErrorScreen && (
        <>
          <CameraPermissionsErrorScreenSkinner
            AppleComponent={permissionsErrorScreen}
            AndroidComponent={androidPermissionsErrorScreen}
          />
          <MicrophonePermissionsErrorScreenSkinner
            AppleComponent={permissionsErrorScreen}
            AndroidComponent={androidPermissionsErrorScreen}
          />
        </>
      )}
      {motionPermissionsErrorScreen && (
        <MotionPermissionsErrorScreenSkinner
          Component={motionPermissionsErrorScreen}
        />
      )}
      {runtimeErrorScreen && (
        <RuntimeErrorSkinner Component={runtimeErrorScreen} />
      )}
    </>
  );
}

ARSkinner.propTypes = {
  promptScreen: PropTypes.elementType,
  loadingScreen: PropTypes.elementType,
  permissionsErrorScreen: PropTypes.elementType,
  androidPermissionsErrorScreen: PropTypes.elementType,
  motionPermissionsErrorScreen: PropTypes.elementType,
  runtimeErrorScreen: PropTypes.elementType,
};

export default ARSkinner;
