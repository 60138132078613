import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { hideContent } from 'src/components/8thWallSkinners/utils';

import useMount from 'src/hooks/useMount';

import 'src/styles/8thwall.scss';

function RuntimeErrorSkinner({ Component }) {
  const containerRef = useRef();
  const [containerExists, setContainerExists] = useState(false);

  useMount(() => {
    const observer = new MutationObserver(() => {
      const container = document.querySelector('#runtimeErrorContainer');

      if (container && !containerRef.current) {
        containerRef.current = container;

        hideContent(container);

        setContainerExists(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return containerExists && containerRef.current
    ? createPortal(<Component />, containerRef.current)
    : null;
}

RuntimeErrorSkinner.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default RuntimeErrorSkinner;
