import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line react-hooks/exhaustive-deps
const useNavigateTo = () => {
  const navigate = useNavigate();

  const navTo = useCallback(
    (to, params) => {
      navigate(to, {
        ...(params || {}),
        data: {
          inAppRedirect: true,
          ...(params?.data || {}),
        },
      });
    },
    [navigate],
  );

  return navTo;
};

export default useNavigateTo;
