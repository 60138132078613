export const clickedHomePageCTA = 'FCB_clicked-home-page-cta';
export const pleaseEnableArAccessContinue =
  'FCB_please-enable-ar-access-continue';
export const pleaseEnableArAccessCancel = 'FCB_please-enable-ar-access-cancel';
export const realityReady = 'FCB_reality-ready';
export const photoTaken = 'FCB_photo-taken';
export const photoShared = 'FCB_photo-shared';
export const videoRecorded = 'FCB_video-recorded';
export const videoShared = 'FCB_video-shared';
export const cameraPermissionsProvided = 'FCB_camera-permissions-provided';
export const locationPermissionsProvided = 'FCB_location-permissions-provided';
export const enteredCameraView = 'FCB_entered-camera-view';
export const startScanning = 'FCB_start-scanning';
export const giftPlaced = 'FCB_gift-placed';
