// const { THREE } = window;
import store from 'src/store';

const SCREENSHOT_DELAY = 1000;
const giftDetectorComponent = {
  init() {
    this.takeScreenshot = this.takeScreenshot.bind(this);
    this.stopScreenshots = this.stopScreenshots.bind(this);
    this.resumeScreenshots = this.resumeScreenshots.bind(this);

    // Take initial screenshot
    setTimeout(() => {
      this.takeScreenshot();
    }, 1000);

    // Set screenshot interval to 3s
    this.screenshotInterval = setInterval(
      this.takeScreenshot,
      SCREENSHOT_DELAY,
    );
    window.addEventListener('stopScanning', this.stopScreenshots);
    window.addEventListener('RESTART_SCANNING', this.resumeScreenshots);
  },
  takeScreenshot() {
    if (store.app.xrReady.value) {
      XR8.CanvasScreenshot.takeScreenshot().then(
        (data) => {
          // Emit a custom event with the screenshot data
          const event = new CustomEvent('screenshotTaken', {
            detail: { imageData: data },
          });
          window.dispatchEvent(event);

          // console.log('Screenshot taken and event dispatched');
        },
        (error) => {
          console.error('Screenshot error:', error);
        },
      );
    }
  },
  stopScreenshots() {
    console.log('Stopping scanning');
    clearInterval(this.screenshotInterval);
  },
  resumeScreenshots() {
    clearInterval(this.screenshotInterval);
    console.log('SETTING SCREENSHOT INTERVAL');
    this.screenshotInterval = setInterval(
      this.takeScreenshot,
      SCREENSHOT_DELAY,
    );
  },
  remove() {
    clearInterval(this.screenshotInterval);
  },
};

export default giftDetectorComponent;
