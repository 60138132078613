import { useContext } from 'react';

import { UserPositionContext } from 'src/contextProviders/UserPositionContextProvider';

const useUserPosition = () => {
  const position = useContext(UserPositionContext);

  return position;
};

export default useUserPosition;
