import { translation } from '../../../utils/translations';

import store from 'src/store';

import {
  TAP_TO_PLACE__SHOW_OVERLAY,
  PRESENT_FOUND,
  GIFT_BOX__TAPPED,
} from './events';

const { THREE } = window;

const CONTENT_SCALE = 0.75;

// const CAMERA_Y_POSITION = 1.6;

const FONT_REGULAR =
  'font:/fonts/Heebo-Regular-msdf.json; fontImage: /fonts/Heebo-Regular.png;';
const FONT_BOLD =
  'font:/fonts/Heebo-Bold-msdf.json; fontImage: /fonts/Heebo-Bold.png;';

const tapToPlaceComponent = {
  init() {
    console.log('INITIALIZING TAP TO PLACE');
    this.raycaster = new THREE.Raycaster();
    this.rayOrigin = new THREE.Vector2(0, 0);
    this.cursorVector = new THREE.Vector3(0, 0, 0);
    // Function binding
    this.placeObject = this.placeObject.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.copyCameraPosition = this.copyCameraPosition.bind(this);
    this.copyCameraYRotation = this.copyCameraYRotation.bind(this);
    this.showProductCard = this.showProductCard.bind(this);
    this.setGiftBoxPosition = this.setGiftBoxPosition.bind(this);
    this.productFound = this.productFound.bind(this);
    this.restartExperience = this.restartExperience.bind(this);
    this.screenTapped = this.screenTapped.bind(this);
    this.tapGroundToPlace = this.tapGroundToPlace.bind(this);

    // Core scene elems
    const ground = document.getElementById('ground');
    const existingBox = document.querySelector('.gift-box-obj');
    const product3DWrapper = document.getElementById('wrap-content');
    const mainLight = document.getElementById('main-light');
    // const experienceWrapper = document.getElementById('experience');

    // Property binding
    this.ground = ground;
    // this.experienceWrapper = experienceWrapper;
    this.cameraElem = document.getElementById('camera');
    this.threeCamera = this.cameraElem.getObject3D('camera');
    this.existingBox = existingBox;
    this.existingBoxIsVisible = existingBox.getAttribute('visible');
    this.camera = this.el.sceneEl.camera.el.object3D;
    this.cameraPosition = new THREE.Vector3();
    this.product3DWrapper = product3DWrapper;
    this.shouldFollowCamera = false;
    this.textureLoader = new THREE.TextureLoader();
    this.translations = translation();
    this.giftBoxPositionSet = false;
    this.cursorLocation = false;
    this.giftBox = existingBox;
    this.mainLight = mainLight;

    // this.textureLoader.crossOrigin = 'anonymous';
    this.textureLoader.setCrossOrigin('anonymous');

    // Event handlers
    window.dispatchEvent(new Event(TAP_TO_PLACE__SHOW_OVERLAY));

    // window.addEventListener(PRESENT_FOUND, this.placeObject);
    window.addEventListener('PRODUCT_FOUND', this.productFound);
    window.addEventListener('TAP_FOR_A_GIFT_FROM_US', this.productFound);
    window.addEventListener('RESTART_SCANNING', this.restartExperience);

    // Initialize placement of object on ground
    this.ground.addEventListener('click', this.tapGroundToPlace);
  },
  tapGroundToPlaceOld(event) {
    // The raycaster gives a location of the touch in the scene
    const touchPoint = event.detail.intersection.point;
    if (this.objectFound && !this.giftBoxPositionSet) {
      this.existingBox.setAttribute('position', touchPoint);

      // update y position to make it hover
      const groundPlaneToCameraDelta =
        this.camera.position.y - this.ground.object3D.position.y;
      console.log('GROUND PLANE TO CAM DELTA', groundPlaneToCameraDelta);
      this.existingBox.object3D.position.y = groundPlaneToCameraDelta * 0.4;
      this.placeObject();
      this.giftBoxPositionSet = true;
    }
  },
  tapGroundToPlace() {
    if (this.objectFound && !this.giftBoxPositionSet) {
      // console.log('UPDATING GIFT OBJECT POSITION')
      // Get camera's forward direction
      const forward = new THREE.Vector3(0, 0, -1);
      forward.applyQuaternion(this.camera.quaternion);
      forward.y = 0; // Zero out y component to keep it level
      forward.normalize();

      // Calculate position 5 feet (1.5m) in front of camera
      const position = new THREE.Vector3();
      position.copy(this.camera.position);
      forward.multiplyScalar(1.5); // 1.5 meters = ~5 feet
      position.add(forward);

      // Keep x and z from calculated position, but use ground's y position
      const groundPosition = this.ground.object3D.position.y;
      position.y = groundPosition;

      // Set the box position
      this.existingBox.setAttribute('position', position);

      // Adjust y position to make it float at eye level
      this.existingBox.object3D.position.y = this.camera.position.y * 0.8; // Slightly below eye level

      this.placeObject();
      this.giftBoxPositionSet = true;
    }
  },
  screenTapped() {
    if (this.objectFound && this.giftBoxPositionSet) {
      this.openGift();
    }
  },
  updateProduct() {
    // Get scene refs
    // const wrapperContent = document.getElementById('wrap-content');
    // const redeemNoCode = document.getElementById('product-nocode');
    // const redeemCode = document.getElementById('product-redeem');
    const product = document.getElementById('product');

    const containerDiscount = document.getElementById('container-discount');
    const textDiscount = document.getElementById('text-discount');
    const textDiscount2ndLine = document.getElementById('text-discount-2-line');

    const businessName = document.getElementById('business-name');
    const businessNameBackground = document.getElementById(
      'business-name-background',
    );
    const businessSegment = document.getElementById('business-segment');
    const businessSegmentBackground = document.getElementById(
      'business-segment-background',
    );

    // dynamic embedded copy
    const textUnwrap = document.getElementById('text-unwrap');
    const fromText = document.getElementById('from');

    // const discount = document.getElementById('redeem-disc');
    // const code = document.querySelectorAll('.redeem-code');

    // const expDates = document.querySelectorAll('.exp-date-end');
    // const business = document.querySelectorAll('.bussines-name');
    // const website = document.querySelectorAll('.website');
    // const web = document.querySelectorAll('.web');

    const selectedProduct = store.app.currentProduct.value;
    // console.log('SELECTED PRODUCT', selectedProduct);
    if (selectedProduct) {
      // Set discount
      if (selectedProduct.couponCode) {
        containerDiscount.setAttribute('visible', 'true');
        textDiscount.setAttribute(
          'text',
          `value: ${store.app.userLanguage.value === 'en' ? '$' : ''}${
            selectedProduct.couponValue
          }${store.app.userLanguage.value === 'fr' ? ' $' : ''}${
            store.app.userLanguage.value === 'fr' ? '\n' : ''
          }${
            store.app.userLanguage.value === 'en'
              ? this.translations.sections.product3DView.copy
                  .couponCodeAmountCopy
              : ''
          }; color: #0075BE; width: 6.5; align: center; negate:false; shader: flat; ${FONT_BOLD}`,
        );

        if (store.app.userLanguage.value === 'fr') {
          textDiscount.setAttribute('position', '0 0.06 0.007');

          textDiscount2ndLine.setAttribute(
            'text',
            `value: ${this.translations.sections.product3DView.copy.couponCodeAmountCopy}; color: #0075BE; width: 3.7; align: center; negate:false; shader: flat; ${FONT_BOLD}`,
          );
          textDiscount2ndLine.setAttribute('position', '0 -0.2 0.007');
        } else {
          textDiscount.setAttribute('position', '0 0.03 0.007');
        }
      } else {
        containerDiscount.setAttribute('visible', 'false');
      }

      const isBusinessSegmentLong = selectedProduct.segment.length > 24;
      const shouldUseBusinessNameBackgroundLong =
        selectedProduct.business_name.length > 24 || isBusinessSegmentLong;

      const sanitizedBusinessName = selectedProduct.business_name.replace(
        '&amp;',
        '&',
      );
      businessName.setAttribute(
        'text',
        `value: ${sanitizedBusinessName}; color: #0075BE; width: 4; align: center; negate:false; shader: flat; ${FONT_BOLD}`,
      );

      businessNameBackground.setAttribute(
        'material',
        `src: url(/images/business-name-${
          shouldUseBusinessNameBackgroundLong ||
          (store.app.couponCodesActive.value === 'false' &&
            store.app.userLanguage.value === 'fr')
            ? 'long'
            : 'short'
        }.png) transparent: true; shader: flat; alphaTest: 0.5;`,
      );

      const sanitizedSegmentName = selectedProduct.segment.replace(
        '&amp;',
        '&',
      );
      businessSegment.setAttribute(
        'text',
        `value: ${sanitizedSegmentName}; color: white; align: center; width: 3; negate:false; shader: flat; ${FONT_REGULAR}`,
      );

      businessSegmentBackground.setAttribute(
        'material',
        `src: url(/images/business-segment-${
          isBusinessSegmentLong ? 'long' : 'short'
        }.png) transparent: true; shader: flat; alphaTest: 0.5;`,
      );
      // Set you wrapped text
      textUnwrap.setAttribute(
        'text',
        `
          value: ${
            this.translations.sections.product3DView.copy.couponCodeLine1
          };
          color: black;
          ${
            store.app.userLanguage.value === 'en'
              ? 'width: 2.5;'
              : 'width: 0.6; wrapCount: 10;'
          }
          align: center;
          negate: false;
          ${FONT_REGULAR}
        `,
      );

      if (store.app.userLanguage.value === 'fr') {
        textUnwrap.setAttribute('position', '0 0.32 0.01');
      }

      fromText.setAttribute(
        'text',
        `
          value: ${
            store.app.couponCodesActive.value !== 'false'
              ? this.translations.sections.product3DView.copy
                  .productCompanyNamePre
              : this.translations.sections.product3DView.copy
                  .productCompanyNamePreNoCode
          };
          color: #646C76;
          width: 2.5;
          align: center;
          negate: false;
          ${FONT_BOLD}`,
      );

      // discount.innerText = selectedProduct.disc;

      // Update image
      const productObj3d = product.object3D;
      this.textureLoader.load(
        `https://d1sg2qphj2ce8c.cloudfront.net/${selectedProduct.product_id}.jpg`, // .product_image, // URL of the image
        (texture) => {
          // On load, update the material of the product
          const productImageMaterial = new THREE.MeshBasicMaterial({
            map: texture,
          });

          // Assuming the product is a mesh, update its material
          productObj3d.traverse((child) => {
            if (child.isMesh) {
              child.material = productImageMaterial;
              child.castShadow = true;
            }
          });

          console.log('Image loaded and material updated');
        },
        undefined, // onProgress callback (optional)
        (err) => {
          console.error('Error loading image', err);
        },
      );

      this.shouldFollowCamera = true;
    }
  },
  placeObject() {
    const existingBoxIsVisible = this.existingBox.getAttribute('visible');
    window.dispatchEvent(new Event(PRESENT_FOUND));
    const boxPosition = this.existingBox.object3D.position;
    const cameraPosition = this.camera.position;
    const distance = boxPosition.distanceTo(cameraPosition);
    // console.log('Distance', distance);

    // Base scale factor (adjust these values to tune the size)
    const baseDistance = 1.5; // Reference distance (5 feet = 1.5m)
    const baseScale = 0.7; // Scale at reference distance
    const scale = (distance / baseDistance) * baseScale;

    const lightOffset = new THREE.Vector3(-4, 20, 15); // Original light offset
    const newLightPosition = new THREE.Vector3()
      .copy(boxPosition)
      .add(lightOffset);

    this.mainLight.setAttribute('position', {
      x: newLightPosition.x,
      y: newLightPosition.y,
      z: newLightPosition.z,
    });
    // console.log('Final scale', scale);
    // console.log('New light position', newLightPosition);

    // delaying to allow present animation gif to finish
    if (!existingBoxIsVisible) {
      setTimeout(() => {
        // Add gift box to scene
        const giftBoxElem = this.existingBox;
        this.giftBox = giftBoxElem;
        // const touchPoint = event.detail.intersection.point;
        // giftBoxElem.setAttribute('position', touchPoint);
        giftBoxElem.setAttribute('visible', 'true');
        giftBoxElem.setAttribute(
          'animation',
          `property: scale; to: 0.25 0.25 0.25; loop: false; dur: 1500; easing: easeInOutQuad;`,
        );

        this.giftBox.setAttribute(
          'animation-mixer',
          'clip: Shake; loop:repeat;',
        );

        const idleSound = document.getElementById('idle');
        idleSound.components.sound.playSound();

        // Event handler for tapping box
        this.el.sceneEl.addEventListener('click', this.screenTapped);
      }, 2000);
    }
    // this.el.removeAttribute('tap-to-place');
  },
  showProductCard() {
    this.updateProduct();
    setTimeout(() => {
      this.product3DWrapper.setAttribute('visible', 'true');
      this.product3DWrapper.setAttribute('animation', {
        property: 'scale',
        from: '0 0 0',
        to: `${CONTENT_SCALE} ${CONTENT_SCALE} ${CONTENT_SCALE}`,
        duration: 4000,
        easing: 'linear',
      });
    }, 2000);
  },
  openGift() {
    this.giftBox.setAttribute(
      'animation-mixer',
      'clip: Unwrap; loop:once; clampWhenFinished: true;',
    );

    const idleSound = document.getElementById('idle');
    console.log('idleSound.components.sound:', idleSound.components.sound);
    idleSound.components.sound.stopSound();

    this.showProductCard();

    const unwrapSoundEntity = document.getElementById('unwrap');
    unwrapSoundEntity.components.sound.playSound();

    window.dispatchEvent(new Event(GIFT_BOX__TAPPED));
  },
  copyCameraPosition() {
    if (this.shouldFollowCamera) {
      const lookAtYPoint = new THREE.Vector3(
        this.camera.position.x,
        this.product3DWrapper.object3D.position.y,
        this.camera.position.z,
      );
      this.product3DWrapper.object3D.lookAt(lookAtYPoint);
    }
  },
  copyCameraYRotation() {
    this.product3DWrapper.object3D.rotation.x = 0;
    this.product3DWrapper.object3D.rotation.y = this.camera.rotation.y;
    this.product3DWrapper.object3D.rotation.z = 0;
  },
  setGiftBoxPosition() {
    console.log('Set gift box pos');
  },
  productFound() {
    this.objectFound = true;
  },
  restartExperience() {
    console.log('Restart Experience');
    this.objectFound = false;
    this.giftBoxPositionSet = false;
    this.existingBox.setAttribute('scale', '0 0 0');
    this.existingBox.setAttribute('visible', 'false');
    this.existingBox.removeAttribute('animation');
    this.existingBox.removeAttribute('animation-mixer');
    this.product3DWrapper.setAttribute('visible', 'false');
    this.product3DWrapper.removeAttribute('animation');
  },
  tick() {
    this.copyCameraPosition();
    this.copyCameraYRotation();
    this.tapGroundToPlace();
    // this.raycaster.setFromCamera(this.rayOrigin, this.threeCamera);
    // const intersects = this.raycaster.intersectObject(
    //   this.ground.object3D,
    //   true,
    // );
    // if (intersects.length > 0) {
    //   const [intersect] = intersects;
    //   this.cursorLocation = intersect.point;
    //   this.cursorVector = intersect.point;

    //   this.phantomCursor.object3D.position.y = 0.1;
    //   this.phantomCursor.object3D.position.lerp(this.cursorVector, 0.4);
    //   this.phantomCursor.object3D.rotation.y = this.threeCamera.rotation.y;

    //   if (this.objectFound && !this.giftBoxPositionSet) {
    //     // console.log('Setting position and placing box', this.cursorVector);
    //     // this.existingBox.object3D.position.y = 0.1;
    //     // this.existingBox.object3D.position.lerp(this.cursorVector, 0.4);
    //     // this.existingBox.object3D.rotation.y = this.threeCamera.rotation.y;
    //     // this.phantomCursor.object3D.position.y = 0.1;
    //     // this.phantomCursor.object3D.position.lerp(this.cursorVector, 0.4);
    //     // this.phantomCursor.object3D.rotation.y = this.threeCamera.rotation.y;

    //     // set gift box to phantom cursor location
    //     this.existingBox.setAttribute(
    //       'position',
    //       this.phantomCursor.object3D.position,
    //     );
    //     this.placeObject();
    //     this.giftBoxPositionSet = true;
    // }
    // }

    // this.setGiftBoxPosition();
  },
  remove() {
    window.removeEventListener(PRESENT_FOUND);
    // this.giftBox.removeAttribute('gift-open-particles');
  },
};

export default tapToPlaceComponent;
