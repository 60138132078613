import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Footer from 'src/components/Footer';
import PermissionsCard from 'src/components/PermissionsCard';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import { promptScreen } from './PromptScreen.module.scss';

function PromptScreen({ onCancel, onContinue }) {
  const handleContinue = useCallback(() => {
    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'global',
          details: 'allow--page',
          region: 'page',
        },
      ],
    });

    onContinue();
  }, [onContinue]);

  const handleCancel = useCallback(() => {
    dispatchAnalyticsEvent({
      event: 'click',
      userEvent: [
        {
          category: 'global',
          details: 'deny--page',
          region: 'page',
        },
      ],
    });
    onCancel();
  }, [onCancel]);

  return (
    <div className={promptScreen}>
      <PermissionsCard onContinue={handleContinue} onCancel={handleCancel} />
      <Footer showLogos />
    </div>
  );
}

PromptScreen.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

export default PromptScreen;
