import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { hideContent } from 'src/components/8thWallSkinners/utils';

import useMount from 'src/hooks/useMount';

import 'src/styles/8thwall.scss';

function MicrophonePermissionsErrorScreenSkinner({
  AppleComponent,
  AndroidComponent,
}) {
  const appleContainerRef = useRef();
  const androidContainerRef = useRef();
  const [appleContainerExists, setAppleContainerExists] = useState(false);
  const [androidContainerExists, setAndroidContainerExists] = useState(false);

  useMount(() => {
    const observer = new MutationObserver(() => {
      const appleContainer = document.querySelector(
        '#microphonePermissionsErrorApple',
      );
      const androidContainer = document.querySelector(
        '#microphonePermissionsErrorAndroid',
      );

      if (appleContainer && !appleContainerRef.current) {
        appleContainerRef.current = appleContainer;

        hideContent(appleContainer);

        setAppleContainerExists(true);
        observer.disconnect();
      }

      if (androidContainer && !androidContainerRef.current) {
        androidContainerRef.current = androidContainer;

        hideContent(androidContainer);

        setAndroidContainerExists(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  return (
    <>
      {appleContainerExists && appleContainerRef.current
        ? createPortal(
            <AppleComponent type="microphone" />,
            appleContainerRef.current,
          )
        : null}
      {androidContainerExists && androidContainerRef.current
        ? createPortal(
            <AndroidComponent type="microphone" />,
            androidContainerRef.current,
          )
        : null}
    </>
  );
}

MicrophonePermissionsErrorScreenSkinner.propTypes = {
  AppleComponent: PropTypes.elementType.isRequired,
  AndroidComponent: PropTypes.elementType.isRequired,
};

export default MicrophonePermissionsErrorScreenSkinner;
