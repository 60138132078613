// import worldComponent from '../../aframe/components/physics/physicsWorld';
// import audioController from 'src/aframe/components/audioController';
// import giftOpenParticles from 'src/aframe/components/giftOpenParticles';
import handleParticles from '../../aframe/components/handle-particles';
import particleComponent from '../../aframe/components/particle-component';
import particleScene from '../../aframe/components/particle-scene';

import animationPlayer from 'src/aframe/components/animationPlayer';
import fixRelativeScale from 'src/aframe/components/fixRelativeScale';
import giftDetectorComponent from 'src/aframe/components/giftDetector';
import postprocessing from 'src/aframe/components/postprocessing';
import reactBridgeComponent from 'src/aframe/components/reactBridge';
import tapToPlaceComponent from 'src/aframe/components/tapToPlace';

const components = [
  {
    name: 'react-bridge',
    val: reactBridgeComponent,
  },
  {
    name: 'tap-to-place',
    val: tapToPlaceComponent,
  },
  {
    name: 'animation-player',
    val: animationPlayer,
  },
  {
    name: 'postprocessing',
    val: postprocessing,
  },
  {
    name: 'gift-detector',
    val: giftDetectorComponent,
  },
  {
    name: 'open-particles',
    val: handleParticles,
  },
  {
    name: 'particles',
    val: particleComponent,
  },
  {
    name: 'fix-relative-scale',
    val: fixRelativeScale,
  },
  {
    name: 'snow-particles',
    val: particleScene,
  }
];

export default components;
