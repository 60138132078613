import React from 'react';

import Footer from '../../components/Footer';
import useTranslation from '../../hooks/useTranslation';

import {
  permissionsRequiredScreen,
  text,
  textBold,
  wrapper,
} from './MotionPermissionsErrorScreen.module.scss';

function MotionPermissionsErrorScreen() {
  const translations = useTranslation();

  return (
    <div className={permissionsRequiredScreen}>
      <div className={wrapper}>
        <p className={text}>
          {translations.sections.permissionsRequiredMotion.bodyP1}
        </p>
        <p className={textBold}>
          {translations.sections.permissionsRequiredMotion.bodyP2}
        </p>
      </div>
      <Footer showLogos />
    </div>
  );
}

export default MotionPermissionsErrorScreen;
