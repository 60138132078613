import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useMount from 'src/hooks/useMount';

function AccessControl({ children }) {
  const [mounted, setMounted] = useState(false);
  const location = useLocation();

  useMount(() => {
    const { search, state, pathname } = location;
    const query = new URLSearchParams(search);
    const reloaded = query.get('reloaded');

    if (reloaded !== 'true' && pathname !== '/experience/get-permissions') {
      const { inAppRedirect } = state || {};
      if (!inAppRedirect) {
        window.location.replace(`/${search}`);
      } else {
        setMounted(true);
      }
    } else {
      setMounted(true);
    }
  });

  return mounted ? children : null;
}

AccessControl.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccessControl;
