const reactBridgeComponent = {
  init() {
    this.el.sceneEl.addEventListener('realityready', this.handleRealityReady);
    this.el.sceneEl.addEventListener('realityerror', this.handleRealityError);
  },
  handleRealityReady() {
    if (window.handleRealityReady) {
      window.handleRealityReady();
    }
  },
  handleRealityError() {
    if (window.handleRealityError) {
      window.handleRealityError();
    }
  },
};

export default reactBridgeComponent;
