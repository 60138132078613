import { useRef } from 'react';

import splash from 'src/assets/images/splash.gif';
import logo from 'src/assets/svgs/logo-bmo.svg';
import retry from 'src/assets/svgs/retry.svg';
import scanWhite from 'src/assets/svgs/scan-white.svg';
import scan from 'src/assets/svgs/scan.svg';
import soundWhite from 'src/assets/svgs/sound-white.svg';
import sound from 'src/assets/svgs/sound.svg';
import unwrap from 'src/assets/svgs/unwrap.svg';

import useMount from './useMount';

const images = [
  splash,
  logo,
  retry,
  scanWhite,
  scan,
  soundWhite,
  sound,
  unwrap,
];

function usePreloadAssets() {
  const assets = useRef([]).current;
  // const teams = useSelector(selectContentTeams);

  useMount(() => {
    images.forEach((img) => {
      const image = new Image();
      image.src = img;

      assets.push(image);
    });
  });
}

export default usePreloadAssets;
