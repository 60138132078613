import Footer from '../../components/Footer';

import { loadingScreen, loader } from './LoadingScreen.module.scss';

function LoadingScreen() {
  return (
    <div className={loadingScreen}>
      <div className={loader} />
      <Footer showLogos showText={false} />
    </div>
  );
}

export default LoadingScreen;
