import React, { useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import useMount from '../../hooks/useMount';

import Button from 'src/components/Button';

import useTranslation from 'src/hooks/useTranslation';

import dispatchAnalyticsEvent from 'src/utils/dispatchAnalyticsEvent';

import store from 'src/store';

import {
  runtimeErrorScreen,
  overlay,
  wrapper,
  title,
  text,
} from './RuntimeErrorScreen.module.scss';

function RuntimeErrorScreen() {
  const translations = useTranslation();
  const handleClick = useCallback(() => {
    window.location.replace(
      `/experience?reloaded=true${
        store.app.userLanguage.value === 'fr' ? '&lang=fr' : ''
      }`,
    );
  }, []);

  useMount(() => {
    dispatchAnalyticsEvent({
      event: 'pageView',
      page: {
        language: store.app.userLanguage.value,
        authenticated: 'logged out',
        name: 'bmo:uwtg:runtimeerror',
        channel: 'bmo',
        site: 'bmo:uwtg',
        section1: 'bmo:uwtg:runtimeerror',
        section2: '',
        section3: '',
      },
      user: {
        darid: '',
        sessionid: '',
        customerType: '',
        productLoginType: '',
      },
      products: [],
      environment: {
        platform: `${isMobile ? 'mobileweb' : 'desktopweb'} - ( ${
          window.navigator.userAgent
        } )`,
        version: '',
      },
      conversion: {
        type: '',
        name: '',
        id: '',
        stage: '',
        applicationid: '',
      },
      notification: [
        {
          code: 'rte',
          type: 'error', // possible values "error" or "warning"
          display: 'page', // possible values based on "inline","page","banner"
        },
      ],
      custom: {
        variables: {},
        events: '',
      },
    });

    dispatchAnalyticsEvent({
      event: 'notify',
      userEvent: [
        {
          category: 'single',
          code: 'failed to load experience - rte',
          type: 'error',
          display: 'page',
        },
      ],
    });

    dispatchAnalyticsEvent({
      event: 'notify',
      userEvent: [
        {
          category: 'single',
          code: 'ar experience failed to load',
          type: 'error',
          display: 'inline',
        },
      ],
    });
  });

  return (
    <div className={runtimeErrorScreen}>
      <div className={wrapper}>
        <h1 className={title}>{translations.sections.runtimeError.header}</h1>
        <h1 className={text}>{translations.sections.runtimeError.body}</h1>
        <Button onClick={handleClick}>
          {translations.sections.runtimeError.btnCopy}
        </Button>
      </div>
      <div className={overlay} />
    </div>
  );
}

export default RuntimeErrorScreen;
